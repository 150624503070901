import React, { useState } from 'react';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import qs from 'query-string';
import { Switch, Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Tabs from '@material-ui/core/Tabs';
import pick from 'lodash/pick';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import joinUrl from 'utils/joinUrl';
import Card from 'views/components/layout/Card';
import Panel from 'views/components/layout/Panel';
import RulesetsTab from './HuntTabs/RulesetsTab/RuelsetsTabContainer';
import LiveHuntTab from './HuntTabs/LiveHuntTab/LiveHuntTabContainer';
import HistoricalHuntTab from './HuntTabs/HistoricalHuntTab/HistoricalHuntTabContainer';
import { NewButton } from './NewButton';
import { Filter } from './Filter';
import { Toolbar } from './Toolbar';
import { useIsTabEnabled } from 'hooks/useIsTabEnabled';
import { Route, Tab } from 'tenants/components';

const messages = {
  rulesets: 'Ruleset and Hunt Management',
  liveHunt: 'Live Hunting Feed',
  historicalHunt: 'Historical Hunts',
};

const FILTER_DEFAULT_VALUES = {
  rule_name: '',
  family: '',
  community: 'default',
  polyscore_lower: 0,
  polyscore_upper: 1,
};

const FILTER_KEYS = Object.keys(FILTER_DEFAULT_VALUES);

const HuntPanel = () => {
  const [showActive] = useState(false);
  const [selectedHunts, setSelectedHunts] = useState<string[]>([]);
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false);

  const tab = useIsTabEnabled();
  const { classes } = useStyles();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { hunt_id, ...otherQueryParams } = qs.parse(location.search);

  const filterKeys = pick(
    !isEmpty(otherQueryParams)
      ? otherQueryParams
      : pick(FILTER_DEFAULT_VALUES, ['community', 'polyscore_lower', 'polyscore_upper']),
    FILTER_KEYS
  );

  const urls = {
    rulesets: joinUrl(match.url, 'rulesets'),
    liveHunt: joinUrl(match.url, 'live'),
    historicalHunt: joinUrl(match.url, 'historical'),
  };

  const _handleChange = (_: React.ChangeEvent<any>, pathname: string) => history.push(pathname);
  const _cleanSelectedHunt = () => setSelectedHunts([]);
  const _toggleHunts = (value: boolean, ids: string | string[]) => {
    if (Array.isArray(ids)) {
      setSelectedHunts(ids);
    } else if (value) {
      setSelectedHunts([...selectedHunts, ids]);
    } else {
      setSelectedHunts(selectedHunts.filter((item) => item !== ids));
    }
  };

  return (
    <Card>
      <Tabs
        indicatorColor='primary'
        value={location.pathname === '/hunt' ? '/hunt/live' : location.pathname}
        onChange={_handleChange}
      >
        <Tab
          value={urls.rulesets}
          label={messages.rulesets}
          onClick={_cleanSelectedHunt}
          data-cy='huntRulesets'
          show={tab.isEnabled('hunt', 'rulesets')}
        />
        <Tab
          value={urls.liveHunt}
          label={messages.liveHunt}
          onClick={_cleanSelectedHunt}
          data-cy='huntLive'
          show={tab.isEnabled('hunt', 'liveHunt')}
        />
        <Tab
          value={urls.historicalHunt}
          label={messages.historicalHunt}
          onClick={_cleanSelectedHunt}
          data-cy='huntHistorical'
          show={tab.isEnabled('hunt', 'historicalHunt')}
        />
      </Tabs>
      {location.pathname === urls.rulesets && <NewButton />}
      {(location.pathname === urls.liveHunt || hunt_id) && (
        <Filter
          isFilterOpen={isFilterOpen}
          onFilterOpen={() => setFilterOpen(true)}
          onFilterClose={() => setFilterOpen(false)}
        />
      )}
      <div css={classes.actionContainer}>
        <div css={classes.tags}>
          {(location.pathname === urls.liveHunt || hunt_id) &&
            map(filterKeys, (value, key) => (
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  setFilterOpen(true);
                }}
                css={classes.tag}
                key={key}
              >
                {key === 'ruleName' ? 'rule name' : (key.split('_').join(' ') as string)}: {value}
              </span>
            ))}
        </div>
        <Toolbar selectedHunts={selectedHunts} setSelectedHunts={setSelectedHunts} />
      </div>
      <Panel>
        <Switch>
          <Route
            exact
            path={urls.rulesets}
            show={tab.isEnabled('hunt', 'rulesets')}
            render={() => <RulesetsTab />}
          />
          <Route
            exact
            path={urls.liveHunt}
            show={tab.isEnabled('hunt', 'liveHunt')}
            render={() => (
              <LiveHuntTab
                showActive={showActive}
                selectedHunts={selectedHunts}
                toggleHunts={_toggleHunts}
              />
            )}
          />
          <Route
            exact
            path={urls.historicalHunt}
            show={tab.isEnabled('hunt', 'historicalHunt')}
            render={() => (
              <HistoricalHuntTab selectedHunts={selectedHunts} toggleHunts={_toggleHunts} />
            )}
          />
          <Redirect exact from={match.url} to={tab.defaulTab('hunt', urls, 'liveHunt')} />
          <Redirect to='/404' />
        </Switch>
      </Panel>
    </Card>
  );
};

const useStyles = makeStyles({
  base: {
    actionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingRight: 'calc(30px - 0.8rem)',
      paddingLeft: 35,
      paddingTop: 18,
    },
    tags: {
      display: 'flex',
      gap: '1rem',
      margin: '1rem 0',
      padding: '0 3rem',
    },
    tag: {
      background: '#D9D9D9',
      color: styles.color.black,
      padding: '0.4rem 1.8rem',
      borderRadius: '2rem',
      fontSize: '1.4rem',
      fontWeight: 600,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
    },
  },
  light: {},
  dark: {},
});
export default HuntPanel;
