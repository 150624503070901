import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { Toggle } from 'views/components/Toggle';
import { usePivoting } from 'views/components/providers/PivotingProvider';
import styled from '@emotion/styled';
import styles from 'views/styles';

const TableContainer = styled.div<{ isDarkTheme: boolean }>`
  overflow: auto;
  max-height: 500px;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0.5rem;
    height: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.4rem;
    background-color: ${({ isDarkTheme }) =>
      isDarkTheme ? 'rgba(255, 255, 255, .5)' : 'rgba(0, 0, 0, .5)'};
  },
`;
const Table = styled.table`
  width: 100%;
`;
const StyledTableHead = styled.thead`
  & td {
    color: ${styles.color.black};
    font-weight: bold;
    background: ${styles.color.xxLightGrey};
    font-size: 1.5rem;
  }
`;

const StyledTableRow = styled.tr`
  background-color: transparent;
`;
const StyledTableCell = styled.td`
  border: 1px solid ${styles.color.xLightGrey};
  padding: 1rem;
  overflow-y: auto;
`;

export default ({
  section,
  id,
  data,
  columns,
}: {
  section?: string;
  id?: string;
  data: any;
  columns?: any;
}) => {
  const { classes, theme } = useStyles();
  const isDarkTheme = theme === 'dark';
  const { active, isPivotable } = usePivoting();

  return data && data.length ? (
    <TableContainer isDarkTheme={isDarkTheme}>
      <Table>
        <StyledTableHead>
          <tr>
            {Object.keys(data[0]).map((name, index) => {
              const keyId = `${id}.${name}`;
              return (
                <td
                  css={[
                    classes.tableCell,
                    active &&
                      isPivotable({ id: keyId, section: section ?? '', item: name }) &&
                      classes.headingHighlighted,
                    classes.header,
                  ]}
                  key={`table-th-index-${index}`}
                >
                  {columns ? columns[name] : name}
                </td>
              );
            })}
          </tr>
        </StyledTableHead>
        <tbody>
          {data.map((row: any, indexRow: number) => (
            <StyledTableRow key={`table-row-index-${indexRow}`}>
              {Object.entries(row).map(([key, value]: any, index) => {
                const keyId = `${id}.${key.toLowerCase()}`;

                return (
                  <StyledTableCell key={`table-row-index-${indexRow}-cell-${index}`}>
                    <Toggle
                      section={section || ''}
                      itemKey={key}
                      id={keyId}
                      itemValue={value?.props?.children || value || ''}
                    >
                      {value}
                    </Toggle>
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  ) : null;
};

const useStyles = makeStyles({
  base: {
    header: {
      textTransform: 'capitalize',
    },
    tableCell: {
      border: `1px solid ${styles.color.xLightGrey}`,
      padding: '1rem',
      overflowY: 'auto',
    },
  },
  light: {
    headingHighlighted: {
      color: `${styles.color.purple} !important`,
    },
  },
  dark: {
    headingHighlighted: {
      color: `${styles.color.lightBlue} !important`,
    },
  },
});
