import React, { useEffect, useRef, useCallback, useState } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { Dispatch } from 'state/types/thunk';
import config from 'config';
import { useAuth } from 'views/components/providers/AuthProvider';
import { RootState } from 'state/root';
import { useUser } from 'views/components/providers/UserProvider';
import { getUserUsage } from 'state/user/actions';
import { getTeamAccount, getTeamByAccount, getTeamUsage } from 'state/team/actions';
import { Artifact } from 'models/Submission';
import useHasFeature from 'hooks/useHasFeature';
import useNotification from 'hooks/useNotification';
import {
  setLoadingState,
  getSubmission,
  rescanFile,
  downloadArtifact,
  getSubmissionByHash,
  getSubmissionByHashAndId,
  getSubmissionByUUID,
  getUrlSubmissionByHash,
} from 'state/submission/actions';
import { errorSelector } from 'state/requests/selectors';
import { RequestError, translateError, ErrorKeys } from 'utils/error';
import { btnMessages } from 'views/components/Button';
import { SubmissionActionName } from 'state/submission/types';
import { getAllVerifiedMicroengines } from 'state/microengines/actions';
import BaseLayout from 'views/components/layout/BaseLayout';
import PageSidebarLayout from 'views/components/layout/PageSidebarLayout';
import { GoToTop } from 'views/components/GoToTop';
import ScanResultsPanel from './ScanResultsPanel';
import { ScanResultsSidebar } from './ScanResultsSidebar';
import SidebarActions from 'views/components/layout/Sidebar/SidebarActions';
import SidebarLoading from 'views/components/layout/Sidebar/SidebarLoading';
import { AxiosError } from 'axios';
import { EMicroengineType } from 'models/Microengine';
import { showNotification } from 'state/notification/actions';
import { closeModal, openModal } from 'state/modal/actions';
import PivotingFilter from 'views/components/Pivoting/Filter';
import { usePivoting } from 'views/components/providers/PivotingProvider';
import ScanHistory from './ScanHistory';
import SEO from 'views/components/layout/SEO';
import { parseScanPageURL } from 'views/url';
import { ContextAccount, contextAccount, readContextAccount } from 'state/auth/selectors';
import { store } from 'state/store';
import { getAccountContext } from 'state/account/actions';
import { isPivotingEnabled } from 'tenants/utils';
import tenant from 'tenants';
import useIsCommunityPlan from 'hooks/useIsCommunityPlan';
interface StateProps {
  isFailed: boolean;
  totalPossibleAssertions: number;
  scanResults: Artifact | null;
  privateContextAccount?: string;
  microengineLength: number;
  error?: RequestError;
  isScanning: boolean;
  isLoading: boolean;
  isRescanning: boolean;
  numMalicious: number;
  numFailed: number;
  totalScans: number;
}

const errorKeys: ErrorKeys = {};

const messages = {
  title: 'Scan',
  downloadNotification:
    'Downloading potentially malicious files is risky. To lessen your risk, we’ve enclosed this artifact in an AES encrypted .zip file with password "infected".',
  sandboxing: 'Sandboxing',
  sandboxingSuccess:
    'Your request to sandbox this artifact has been received. It will take a few minutes to process. Please check the Sandbox tab for results.',
  pivoting: 'Pivot',
  history: 'History',
  resandboxingSuccess:
    'The artifact is queued for sandboxing. You can monitor the status on the Sandbox → My Sandbox tab.',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MAX_POLLING_TIME = 2 * 60 * 1000; // 2 minute

const ScanResultsPage = () => {
  const intl = useIntl();
  const user = useUser();
  const history = useHistory();
  const match = useRouteMatch<{
    uuid: string;
    artifactType: string;
    submissionId?: string;
    action?: string;
  }>();
  const { active, onToggleActive, setArtifactId } = usePivoting();
  const [showScanDetails, setShowScanDetails] = useState(true);
  const [activeInstanceId, setActiveInstanceId] = useState<string>('');
  const [isScanning, setScanning] = useState(false);
  const location = useLocation<{ submissionId?: string } | undefined>();
  const dispatch = useDispatch<Dispatch>();
  const { isAuthenticated, login } = useAuth();
  const { hasPermission: hasSandboxFeature } = useHasFeature('sandbox_request');
  const { hasPermission: downloadPermission, remainingUses: downloadRemainingUses } =
    useHasFeature('download');
  const notification = useNotification();
  const isSandboxDisabledInTenant = tenant.disabledPages?.includes('sandbox');
  const isCommunityPlan = useIsCommunityPlan();

  const noReportFeature = !isAuthenticated || isCommunityPlan;

  const _getSubmissionTimeout = useRef<number>();
  const pollingTimeout = useRef<number>();

  const { fallbackId = '' } = qs.parse(location.search);

  const {
    totalPossibleAssertions,
    scanResults,
    privateContextAccount,
    microengineLength,
    error,
    isFailed,
    isLoading,
    isRescanning,
    numMalicious,
    numFailed,
    totalScans,
  } = useSelector<RootState, StateProps>(({ requests, submission }) => {
    const scanResults = submission.scanResults && submission.scanResults.result;
    const status = submission.scanResults && submission.scanResults.status;
    const privateContextAccount = submission.scanResults?.context_account;
    const microengineLength = submission.emptyAssertions.length;
    const assertions = (scanResults && scanResults?.assertions) ?? [];
    const error = errorSelector(requests, [SubmissionActionName.GET_SUBMISSION]) as AxiosError<any>;
    const isFailed = !!(
      (!!status && status === 'Bounty Failed') ||
      (!!scanResults && scanResults.failed) ||
      (!!scanResults && scanResults.bounty_state === 5)
    );

    const numMalicious = assertions.filter((assertion) => assertion.verdict).length;
    const numFailed = assertions.filter(
      (assertion) => assertion.bid === '0' && assertion.verdict === null
    ).length;
    const totalScans = assertions.filter(
      (assertion) => !['', '0'].includes(assertion?.bid || '') && assertion.verdict !== null
    ).length;
    const totalPossibleAssertions = assertions.filter((item) => !!item.bid).length;

    return {
      scanResults,
      privateContextAccount,
      microengineLength,
      error,
      isScanning,
      isFailed,
      isLoading: submission.loadingExtra,
      isRescanning: submission.isRescanning,
      numMalicious,
      numFailed,
      totalScans,
      totalPossibleAssertions,
    };
  });

  const ctx = contextAccount(store);
  const teamAccount = ctx?.context === 'team';
  const accountNumber = ctx?.accountNumber;
  const scanBounty = !!scanResults ? scanResults.bounty_state : 0;
  const polyScore = !!scanResults ? scanResults.polyscore : undefined;
  const lastScanned = !!scanResults ? scanResults.last_scanned : undefined;

  const [noScanResultsYet, setNoScanResultsYet] = useState(false);

  const getAccountUsage = useCallback(() => {
    if (accountNumber) {
      return dispatch(getUserUsage(accountNumber));
    }
  }, [dispatch, accountNumber]);

  const getTeamAccountUsage = useCallback(() => {
    if (accountNumber && teamAccount) {
      return dispatch(getTeamUsage(accountNumber));
    }
  }, [dispatch, accountNumber, teamAccount]);

  /**
   *
   * Perform scanning based on provided Submission ID or Hash or UUID
   */
  const _scanSubmission = () => {
    // Submission ID can be provided over state, transparent for user OR over URL
    const submissionId = location.state ? location.state.submissionId : match.params.submissionId;

    if (submissionId) {
      dispatch(getSubmissionByHashAndId(match.params.uuid, submissionId));
    } else if (/\/url\//.test(location.pathname)) {
      dispatch(getUrlSubmissionByHash(match.params.uuid));
    } else if (/[-]/.test(match.params.uuid)) {
      dispatch(getSubmissionByUUID(match.params.uuid));
    } else {
      dispatch(getSubmissionByHash(match.params.uuid)).catch(() => {
        if (fallbackId) {
          return dispatch(getSubmissionByHashAndId(match.params.uuid, fallbackId));
        }
      });
    }
  };

  /**
   * Stop polling submission by id
   */
  const _stopPollingSubmission = useCallback((isClearRef?: boolean) => {
    setScanning(false);
    window.clearTimeout(_getSubmissionTimeout.current);
    window.clearTimeout(pollingTimeout.current);

    if (isClearRef) {
      _getSubmissionTimeout.current = -1; // -1 means stopped
      pollingTimeout.current = undefined;
    }
  }, []);

  /**
   * Start polling submission by id until window will be closed
   */
  const _startPollingSubmission = useCallback(() => {
    setScanning(true);
    setNoScanResultsYet(false);

    if (!pollingTimeout.current) {
      pollingTimeout.current = window.setTimeout(() => {
        setNoScanResultsYet(true);
        _stopPollingSubmission(true);
      }, MAX_POLLING_TIME);
    }

    if (!!scanResults && _getSubmissionTimeout.current !== -1) {
      _getSubmissionTimeout.current = window.setInterval(() => {
        dispatch(getSubmission(scanResults.id));
      }, 5000);
    }
  }, [scanResults, dispatch, _stopPollingSubmission]);

  useEffect(() => {
    if (!_getSubmissionTimeout.current && (error || isFailed)) {
      return _stopPollingSubmission(true);
    }

    if (!_getSubmissionTimeout.current && !isScanning) {
      return _startPollingSubmission();
    }

    if (
      (!!_getSubmissionTimeout.current || isScanning) &&
      scanBounty > 2 &&
      scanBounty < 5 &&
      (scanResults?.type === 'FILE' ? !!polyScore || !!lastScanned : !!scanResults?.detections)
    ) {
      return _stopPollingSubmission();
    }

    if (scanBounty === 6) {
      setNoScanResultsYet(true);
      return _stopPollingSubmission();
    }
  }, [
    error,
    isFailed,
    isScanning,
    scanResults?.type,
    scanResults?.assertions,
    scanResults?.detections,
    scanBounty,
    dispatch,
    _startPollingSubmission,
    polyScore,
    lastScanned,
    _stopPollingSubmission,
  ]);

  useEffect(() => {
    if (
      !_getSubmissionTimeout.current &&
      !isEmpty(scanResults) &&
      !!scanResults?.id &&
      !isScanning
    ) {
      _startPollingSubmission();
    }
  }, [_startPollingSubmission, scanResults, scanResults?.id, isScanning]);

  /**
   * Rescan current submission
   */
  const _rescanSubmission = useCallback(() => {
    if (scanResults) {
      dispatch(rescanFile(scanResults.id, scanResults.sha256, scanResults.type));
      _getSubmissionTimeout.current = undefined;
    }
  }, [scanResults, dispatch]);

  const _handleDownload = () => {
    if (!isAuthenticated) {
      login(location.pathname);
    } else if (!downloadPermission) {
      notification.failure(
        'Your subscription does not include this feature. Please contact your sales representative to add it.',
        45000
      );
    } else if (!downloadRemainingUses) {
      notification.failure(
        'Your subscription 0 remaining quota for this feature for this month. Please wait until your quota resets next month and try again.',
        45000
      );
    } else {
      const submissionId = location.state ? location.state.submissionId : match.params.submissionId;
      dispatch(downloadArtifact(scanResults!.sha256, submissionId));
      dispatch(
        showNotification({
          status: 'info',
          message: messages.downloadNotification,
          delay: 45000,
          isDismissible: true,
        })
      );
    }
  };

  const _getTeam = async () => {
    if (teamAccount && accountNumber) {
      await dispatch(getTeamByAccount(accountNumber));
      dispatch(getTeamAccount(accountNumber));
    }
  };

  useEffect(() => {
    _getTeam();
  }, [user.context]); // eslint-disable-line react-hooks/exhaustive-deps

  const _refreshContext = useCallback(async (ctx?: ContextAccount) => {
    await dispatch(getAccountContext(ctx));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (privateContextAccount?.endsWith('-private')) {
      _refreshContext(readContextAccount(privateContextAccount));
    }
  }, [privateContextAccount, _refreshContext]);

  useEffect(() => {
    dispatch(
      getAllVerifiedMicroengines({
        engineType: EMicroengineType.microengine,
        artifactType: match.params.artifactType || 'file',
      })
    );

    return () => {
      // stop intervals when transitions to other pages
      _stopPollingSubmission();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user.accountNumber !== -1) {
      getTeamAccountUsage();
    }
  }, [getTeamAccountUsage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (microengineLength > 0) {
      _scanSubmission();
    }
  }, [microengineLength]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (scanResults && match.params.action && match.params.action === 'rescan') {
      _rescanSubmission();
      history.replace(parseScanPageURL(match.params.uuid));
    }
  }, [scanResults, match.params, _rescanSubmission, history]);

  useEffect(() => {
    if (activeInstanceId && match.params.uuid) {
      dispatch(setLoadingState());
      dispatch(getSubmissionByHashAndId(match.params.uuid, activeInstanceId));
      history.replace(
        `/scan/results/${match.params.artifactType}/${match.params.uuid}/${activeInstanceId}${
          match.params.action ? `/${match.params.action}` : ''
        }`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInstanceId, match.params.uuid]);

  useEffect(() => {
    if (scanResults?.sha256) {
      setArtifactId(scanResults.sha256);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanResults?.sha256]);

  const resandboxing = () =>
    dispatch(
      openModal('SUBMIT_TO_SANDBOX_MODAL', {
        onSubmit: () => {
          dispatch(
            showNotification({
              status: 'success',
              message: messages.resandboxingSuccess,
            })
          );
          dispatch(closeModal());
          getAccountUsage();
        },
        instanceId: scanResults?.id,
        defaultType: match?.params?.artifactType === 'url' ? 'url' : 'hash',
        defaultHash: scanResults?.sha256 ?? match?.params?.uuid,
        isResandbox: true,
      })
    );

  return (
    <BaseLayout>
      {active && <PivotingFilter />}
      <PageSidebarLayout
        renderSidebar={
          error
            ? null
            : () => {
                if (!scanResults) {
                  return <SidebarLoading />;
                }
                return (
                  <Container>
                    <SEO title={`${scanResults.sha256} | ${messages.title}`} />
                    <ScanResultsSidebar
                      totalPossibleAssertions={totalPossibleAssertions}
                      showDetails={showScanDetails}
                      onShowDetailsChange={(show) => setShowScanDetails(show)}
                      artifact={scanResults}
                      isLoading={!noScanResultsYet && isLoading}
                      isScanning={
                        !noScanResultsYet && (isScanning || match.params.action === 'rescan')
                      }
                      numMalicious={numMalicious}
                      numFailed={numFailed}
                      totalScans={totalScans}
                      detections={scanResults.detections}
                      errorMessage={translateError(intl, errorKeys, error)}
                    />
                    {!showScanDetails && !isScanning && (
                      <ScanHistory
                        sha256={match.params.uuid}
                        activeInstanceId={activeInstanceId || scanResults.id}
                        setActiveInstanceId={setActiveInstanceId}
                      />
                    )}
                    {((!error && !isScanning) || noScanResultsYet) && (
                      <SidebarActions
                        className='h-mt-grid'
                        items={[
                          {
                            icon: 'rescan',
                            name: totalScans > 0 ? 'Rescan' : 'Scan',
                            disabled: isRescanning,
                            onClick: () => {
                              dispatch(
                                rescanFile(scanResults.id, scanResults.sha256, scanResults.type)
                              );
                              _getSubmissionTimeout.current = undefined;
                            },
                            dataCy: 'scanRescanBtn',
                          },
                          {
                            icon: 'download',
                            name: intl.formatMessage(btnMessages.download),
                            onClick: _handleDownload,
                            dataCy: 'scanDownloadBtn',
                            disabled: !downloadPermission || scanResults.type === 'URL',
                          },
                          {
                            icon: 'share',
                            name: intl.formatMessage(btnMessages.share),
                            shareUrl: `${config.url}${match.url}`,
                            dataCy: 'scanShareBtn',
                            disabled: noScanResultsYet,
                          },
                          {
                            icon: 'sandbox',
                            name: messages.sandboxing,
                            onClick: async () => {
                              if (isSandboxDisabledInTenant) {
                                return null;
                              } else {
                                if (!hasSandboxFeature) {
                                  history.push(`/pricing/enterprise`);
                                } else {
                                  if (match.params.artifactType === 'url') {
                                    return resandboxing();
                                  }

                                  resandboxing();
                                }
                              }
                            },
                            warning: !hasSandboxFeature || isSandboxDisabledInTenant,
                            disabled: !isAuthenticated,
                            tooltipTitle: !isAuthenticated ? (
                              <>Log in or create an account to begin sandboxing.</>
                            ) : !hasSandboxFeature || isSandboxDisabledInTenant ? (
                              <>
                                Sandboxing is{' '}
                                <span style={{ fontWeight: 'bold' }}>unavailable</span> for current
                                plan.
                              </>
                            ) : (
                              ''
                            ),
                            dataCy: 'sandboxingBtn',
                          },
                          {
                            icon: 'pivot',
                            name: messages.pivoting,
                            dataCy: 'scanPivotingBtn',
                            disabled: !isAuthenticated || noScanResultsYet,
                            isShown: isPivotingEnabled(),
                            tooltipTitle: !isAuthenticated ? (
                              <>Log in or create an account to begin pivoting.</>
                            ) : (
                              ''
                            ),
                            onClick: () => {
                              onToggleActive(scanResults.sha256);
                              setTimeout(() => {
                                if (!active) {
                                  document.getElementById('pivot-search')?.scrollIntoView({
                                    behavior: 'smooth',
                                  });
                                }
                              }, 100);
                            },
                            iconFilled: active,
                          },
                          {
                            icon: 'history',
                            name: messages.history,
                            onClick: () => {
                              setShowScanDetails(!showScanDetails);
                            },
                            disabled: !isAuthenticated || totalScans === 0 || noScanResultsYet,
                            tooltipTitle: !isAuthenticated ? (
                              <>Log in or create an account to see the artifact history.</>
                            ) : totalScans === 0 ? (
                              <>There are no more results available.</>
                            ) : (
                              ''
                            ),
                            dataCy: 'scanHistoryBtn',
                          },
                          {
                            icon: 'generate-report',
                            name: 'Generate Report',
                            onClick: () => {
                              dispatch(
                                openModal('GENERATE_REPORT', {
                                  type: 'scan',
                                  id: scanResults.id,
                                })
                              );
                            },
                            disabled: noReportFeature || noScanResultsYet,
                            tooltipTitle: !isAuthenticated ? (
                              <>Log in or create an account to generate a report.</>
                            ) : noReportFeature ? (
                              <>
                                Generating a report is{' '}
                                <span style={{ fontWeight: 'bold' }}>unavailable</span> for current
                                plan.
                              </>
                            ) : (
                              ''
                            ),
                            warning: noReportFeature,
                            dataCy: 'generateReportBtn',
                          },
                        ]}
                      />
                    )}
                  </Container>
                );
              }
        }
        renderPanel={() => (
          <ScanResultsPanel
            file={scanResults}
            totalScans={totalScans}
            sha256={scanResults?.sha256 || match.params.uuid}
            uuid={match.params.uuid}
            isScanning={!noScanResultsYet && (isScanning || match.params.action === 'rescan')}
            onRetryScan={_scanSubmission}
            onRetryFileDetails={_scanSubmission}
            onRescan={_rescanSubmission}
          />
        )}
      />
      <GoToTop />
    </BaseLayout>
  );
};
export default ScanResultsPage;
