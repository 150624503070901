import { AsyncAction } from 'state/types/actions';
import { ApiKeysRes, ApiKeyRes } from 'services/api/schema/apiKey';

export enum ApiKeysActionName {
  GET_API_KEYS = 'GET_API_KEYS',
  REFRESH_API_KEYS = 'REFRESH_API_KEYS',
  CREATE_API_KEY = 'CREATE_API_KEY',
  ARCHIVE_API_KEY = 'ARCHIVE_API_KEY',
  CLEAR_API_KEYS_STATE = 'CLEAR_API_KEYS_STATE',
  EDIT_TAGS_API_KEY = 'EDIT_TAGS_API_KEY',
}

export interface GetApiKeysAction extends AsyncAction<ApiKeysActionName.GET_API_KEYS, ApiKeysRes> {}

export interface RefreshApiKeysAction
  extends AsyncAction<ApiKeysActionName.REFRESH_API_KEYS, ApiKeysRes> {}

export interface CreateApiKeyAction
  extends AsyncAction<ApiKeysActionName.CREATE_API_KEY, ApiKeyRes> {}

export interface ArchiveApiKeyAction
  extends AsyncAction<ApiKeysActionName.ARCHIVE_API_KEY, ApiKeyRes> {}

export interface ClearApiKeysStateAction
  extends AsyncAction<ApiKeysActionName.CLEAR_API_KEYS_STATE, []> {}

export interface PatchApiKeyTagsStateAction
  extends AsyncAction<ApiKeysActionName.EDIT_TAGS_API_KEY, []> {}

export type ApiKeysAction =
  | GetApiKeysAction
  | RefreshApiKeysAction
  | CreateApiKeyAction
  | ClearApiKeysStateAction
  | ArchiveApiKeyAction
  | PatchApiKeyTagsStateAction;
