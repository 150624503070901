import React from 'react';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import styles from 'views/styles';
import Icon from 'views/components/Icon';

const TableSortArrow = ({ className }: { className?: string }) => (
  <Icon className={className} name='arrow-solid' />
);

const base: ThemeOptions = {
  props: {
    MuiTab: {
      disableRipple: true,
    },
    MuiTableSortLabel: {
      IconComponent: TableSortArrow,
    },
    MuiSelect: {
      IconComponent: TableSortArrow,
    },
  },
  typography: {
    fontSize: 16,
    htmlFontSize: 10,
    fontFamily: styles.font.family.main,
  },
  overrides: {
    MuiPaper: {
      root: {
        boxShadow: `${styles.shadow.default} !important`,
        borderRadius: styles.border.radius,
        minWidth: 450,
      },
    },
    MuiMenuItem: {
      root: {
        fontWeight: styles.font.weight.medium,
      },
    },
    MuiTableRow: {
      root: {
        height: 54,
        transition: `background-color ${styles.easing.time} ${styles.easing.main}`,
        '&$hover:hover': {
          backgroundColor: styles.color.xxLightPurple,
        },
      },
      head: {
        height: 45,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
        padding: 0,
      },
      head: {
        fontSize: '1.2rem',
        fontWeight: styles.font.weight.medium,
      },
    },
    MuiSelect: {
      root: {
        fontWeight: styles.font.weight.medium,
      },
      icon: {
        width: 10,
        height: 5,
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },
    MuiTableSortLabel: {
      icon: {
        width: 10,
        height: 5,
      },
    },
    MuiTooltip: {
      tooltip: {
        boxShadow: styles.shadow.default,
        borderRadius: styles.border.radius,
        padding: '1rem 1.4rem',
        fontSize: styles.font.size.p1,
      },
    },
    MuiTablePagination: {
      toolbar: {
        paddingLeft: 0,
        fontSize: styles.font.size.p1,
      },
      menuItem: {
        fontSize: styles.font.size.p1,
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 38,
        fontWeight: styles.font.weight.medium,
      },
      selectRoot: {
        marginRight: 20,
      },
      select: {
        padding: '8px 22px 8px 6px',
        lineHeight: 1,
      },
      selectIcon: {
        right: 6,
        top: '50%',
        transform: 'translateY(-50%)',
      },
      actions: {
        '& > button': {
          padding: 8,
        },
      },
    },
    MuiButtonBase: {
      root: {
        transition: `background-color ${styles.easing.time} ${styles.easing.main}, box-shadow ${styles.easing.time} ${styles.easing.main}, border ${styles.easing.time} ${styles.easing.main}`,
        '&$disabled': {
          cursor: 'not-allowed',
          pointerEvents: 'auto',
          opacity: 0.6,
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: '2rem',
        padding: '0.66em 1.2em',
        textTransform: 'none',
        borderRadius: styles.border.radiusXl,
      },
      contained: {
        minWidth: '9em',
        fontWeight: styles.font.weight.bold,
      },
      outlined: {
        minWidth: '9em',
        padding: '0.5em 1.2em',
        fontWeight: styles.font.weight.medium,
      },
      text: {
        borderRadius: styles.border.radius,
        fontWeight: styles.font.weight.bold,
      },
    },
    MuiIconButton: {
      root: {
        transition: `background-color ${styles.easing.time} ${styles.easing.main}, box-shadow ${styles.easing.time} ${styles.easing.main}, border ${styles.easing.time} ${styles.easing.main}`,
        padding: 8,
      },
    },
    MuiFab: {
      root: {
        width: 48,
        height: 48,
      },
      label: {
        fontSize: '0.5rem',
      },
    },
    MuiTabs: {
      root: {
        padding: `${styles.spacing.grid} ${styles.spacing.grid} 0`,
        minHeight: 0,
        position: 'relative',
        '&:before': {
          content: '""',
          display: 'block',
          height: 2,
          width: `calc(100% - calc(${styles.spacing.grid} * 2))`,
          left: styles.spacing.grid,
          bottom: 0,
          position: 'absolute',
        },
      },
    },
    MuiTab: {
      root: {
        fontSize: styles.font.size.p1,
        fontWeight: styles.font.weight.medium,
        textTransform: 'none',
        minWidth: '0 !important',
        minHeight: 0,
        padding: '0 0 2rem',
        marginRight: '4rem',
        transition: `color ${styles.easing.time} ${styles.easing.main}`,
        '&:last-child': {
          marginRight: 0,
        },
      },
      textColorInherit: {
        opacity: 1,
        '&$selected': {
          opacity: 1,
        },
        '&$disabled': {
          opacity: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          fontWeight: styles.font.weight.medium,
        },
      },
    },
  },
};

export default base;
