import React, { useState, useCallback } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { Tooltip, Chip, TableCell, TableRow } from '@material-ui/core';
import styled from '@emotion/styled';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { format } from 'date-fns';
import styles from 'views/styles';
import { ApiKeyData } from 'models/ApiKey';
import Icon from 'views/components/Icon';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import BaseHoverableTableRow from 'views/components/table/HoverableTableRow';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import { FULL_DATE_FORMAT } from 'utils/constants';
import { DeleteApiKeyDialog } from '../ApiKeysTable/DeleteApiKeyDialog';
import { UserTeam } from 'models/User';

interface IApiKeyTableRow {
  teams: UserTeam[];
  hasPrivateCommunity: boolean;
  rowData: ApiKeyData;
  archiveApiKey: (id: string, key: string) => void;
  team: number;
  showName?: boolean;
}

const HoverableTableRow = styled(BaseHoverableTableRow)`
  cursor: pointer;
`;

const Actions = styled.div`
  white-space: nowrap;
  display: inline-flex;
`;

const formatDate = (date: Date) => {
  return format(date, FULL_DATE_FORMAT);
};

const formatAPIKey = (input: string) => {
  const dots = '.'.repeat(26);
  const lastFour = input.slice(-4);
  return `${dots}${lastFour}`;
};

const ApiKeyTableRow = ({
  teams,
  hasPrivateCommunity,
  rowData,
  team,
  archiveApiKey,
  showName = true,
}: IApiKeyTableRow) => {
  const [isOpen, setIsOpen] = useState(false);
  const { classes } = useStyles(isOpen);

  const _toggleOpen = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const teamData =
    teams?.find((item) => {
      return String(item.accountNumber) === String(rowData?.team?.account_number);
    }) ?? ({} as UserTeam);

  const community = rowData?.community
    ? capitalize(rowData.community)
    : teamData.hasPrivateCommunity
    ? 'Public, Private'
    : 'Public';

  const HeaderComponent = useCallback(
    () => (
      <>
        {showName ? (
          <TableCell width={300}>
            <div css={classes.cellContainer}>
              <span css={classes.mt}>{rowData.name || '-'}</span>
              {rowData.expiration && new Date(rowData.expiration) <= new Date() ? (
                <Tooltip title='API Key is expired and no longer active' placement='top'>
                  <div>
                    <Icon css={classes.iconWarning} name='warning' />
                  </div>
                </Tooltip>
              ) : null}
            </div>
          </TableCell>
        ) : null}
        {team ? <TableCell width={300}>{rowData.team?.name}</TableCell> : <></>}
        {team && hasPrivateCommunity ? (
          <TableCell width={300}>{capitalize(community)}</TableCell>
        ) : (
          <></>
        )}
        <TableCell>
          <Tooltip title={rowData.key} placement='top-start'>
            <span>
              {`${formatAPIKey(rowData.key)}`} <CopyButton text={rowData.key} />
            </span>
          </Tooltip>
        </TableCell>
        <TableCell>
          {`${rowData.expiration ? formatDate(new Date(rowData.expiration)) : 'Never expires'}`}
        </TableCell>
        <TableCell align='right'>
          <Actions>
            <Tooltip title='Delete API Key' placement='top'>
              <div>
                <DeleteApiKeyDialog onDeleteApiKey={() => archiveApiKey(rowData.id, rowData.key)} />
              </div>
            </Tooltip>
            <button
              style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0)' }}
              css={classes.btnToggle}
              onClick={_toggleOpen}
            >
              <Icon name='arrow' title='toggle' />
            </button>
          </Actions>
        </TableCell>
      </>
    ),
    [isOpen, archiveApiKey, rowData, team, _toggleOpen] // eslint-disable-line
  );

  const scopes = rowData?.scope?.feature_tags ?? [];

  return (
    <>
      <HoverableTableRow renderCells={() => <HeaderComponent />} />
      {isOpen && (
        <TableRow>
          <TableCell colSpan={11}>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '24px',
                marginLeft: 16,
                maxWidth: '100%',
                overflow: 'auto',
              }}
            >
              <label style={{ lineHeight: '1.5', verticalAlign: 'middle' }}>Scopes:</label>
              {!isEmpty(scopes)
                ? scopes.map((scope) => <Chip label={scope} />)
                : 'No scopes available'}
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const CopyButton = styled(CopyTextButton)`
  font-size: 0.4rem;
  margin-left: 0.6rem;
  vertical-align: middle;
`;

const useStyles = makeStyles((isOpen: boolean) => ({
  base: {
    iconWarning: {
      color: styles.color.lightRed,
      width: '1.8rem !important',
      height: '1.8rem !important',
    },
    cellContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
    mt: {
      marginTop: '-5px',
    },
    btnInfo: {
      marginLeft: 8,
      fontSize: '0.6rem',
      color: styles.color.lightGrey,
    },
    btnToggle: {
      cursor: 'pointer',
      fontSize: '0.32rem',
      padding: '0.5rem',
      transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
      transition: `color ${styles.easing.time} ${styles.easing.main}`,
    },
  },
  light: {
    btnToggle: {
      color: styles.color.xLightGrey,
      '&:hover': {
        color: styles.color.purple,
      },
    },
  },
  dark: {
    btnToggle: {
      color: styles.color.xLightGrey,
      '&:hover': {
        color: styles.color.lightBlue,
      },
    },
  },
}));
export default ApiKeyTableRow;
