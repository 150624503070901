import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { Filter } from 'views/components/utils';
import Icon from 'views/components/Icon';

export interface IStatusIcon {
  className?: string;
  sidebar?: boolean;
  status: 'info' | 'ok' | 'error' | 'reload' | 'fail' | 'malicious' | 'warning';
  value?: number | string | null;
}

const StatusIcon = (props: IStatusIcon) => {
  const { className, status, sidebar = false } = props;
  const { classes } = useStyles(status);
  return (
    <div className={className} css={[classes.root, sidebar ? classes.mt5 : null]}>
      {status !== 'warning' ? (
        <Filter
          currentState={status}
          states={{
            info: <IconInfoState {...props} />,
            ok: <IconOkState {...props} />,
            error: <IconErrorState {...props} />,
            reload: <IconReloadState {...props} />,
            fail: <IconFailState {...props} />,
            malicious: <IconMaliciousState {...props} />,
          }}
        />
      ) : null}
      {status === 'warning' ? (
        <svg
          width='112'
          height='123'
          viewBox='0 0 112 123'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            opacity='0.25'
            d='M45.7391 3.09722C52.0886 -0.568636 59.9114 -0.568638 66.2609 3.09722L101.391 23.3798C107.741 27.0456 111.652 33.8204 111.652 41.1521V81.7173C111.652 89.049 107.741 95.8238 101.391 99.4896L66.2609 119.772C59.9114 123.438 52.0886 123.438 45.7391 119.772L10.6087 99.4896C4.25925 95.8238 0.347826 89.049 0.347826 81.7173V41.1521C0.347826 33.8204 4.25925 27.0456 10.6087 23.3798L45.7391 3.09722Z'
            stroke='#FF455B'
            stroke-width='0.695652'
          />
          <path
            d='M47.1304 23.5831C52.6189 20.4143 59.3811 20.4143 64.8696 23.5831L84.3478 34.8289C89.8363 37.9977 93.2174 43.8538 93.2174 50.1914V72.683C93.2174 79.0205 89.8363 84.8767 84.3478 88.0455L64.8696 99.2913C59.3811 102.46 52.6189 102.46 47.1304 99.2913L27.6522 88.0455C22.1637 84.8767 18.7826 79.0205 18.7826 72.683V50.1914C18.7826 43.8538 22.1637 37.9977 27.6522 34.8289L47.1304 23.5831Z'
            fill='#FF455B'
            stroke='#FF455B'
            stroke-width='2.08696'
          />
          <path
            opacity='0.5'
            d='M46.956 13.4015C52.5522 10.1705 59.4468 10.1705 65.043 13.4015L93.0764 29.5866C98.6726 32.8175 102.12 38.7885 102.12 45.2504V77.6206C102.12 84.0825 98.6726 90.0534 93.0764 93.2844L65.043 109.469C59.4468 112.7 52.5521 112.7 46.956 109.469L18.9225 93.2844C13.3264 90.0534 9.87908 84.0825 9.87908 77.6206V45.2503C9.87908 38.7885 13.3264 32.8175 18.9226 29.5866L46.956 13.4015Z'
            stroke='#FF455B'
            stroke-width='1.3913'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M53.5134 76.1342L53.4932 70.337H59.2088L59.1887 76.1342H53.5134Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M59.2118 64.6772H53.5163V47.5217H59.2118V64.6772Z'
            fill='white'
          />
        </svg>
      ) : null}
      {status !== 'malicious' && status !== 'warning' ? (
        <svg
          css={classes.icon}
          viewBox='0 0 162 178'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-155.000000, -150.000000)'>
              <g transform='translate(60.000000, 119.000000)'>
                <g transform='translate(43.000000, 31.000000)'>
                  <g transform='translate(52.000000, 0.000000)'>
                    <path
                      css={classes.outerLine}
                      d='M95.3525404,5.1398734 C86.2252086,-0.129794051 74.9798721,-0.129794051 65.8525404,5.1398734 L15.3525404,34.296062 C6.22520861,39.5657294 0.602540378,49.3044765 0.602540378,59.8438114 L0.602540378,118.156189 C0.602540378,128.695523 6.22520861,138.434271 15.3525404,143.703938 L65.8525404,172.860127 C74.9798721,178.129794 86.2252086,178.129794 95.3525404,172.860127 L145.85254,143.703938 C154.979872,138.434271 160.60254,128.695523 160.60254,118.156189 L160.60254,59.8438114 C160.60254,49.3044765 154.979872,39.5657294 145.85254,34.296062 L95.3525404,5.1398734 Z'
                    />
                    <path
                      css={classes.innerLine}
                      strokeWidth='2'
                      d='M93.6025404,19.9511078 C85.5581124,15.3066552 75.6469684,15.3066552 67.6025404,19.9511078 L27.3044457,43.2172237 C19.2600177,47.8616763 14.3044457,56.4449788 14.3044457,65.7338842 L14.3044457,112.266116 C14.3044457,121.555021 19.2600177,130.138324 27.3044457,134.782776 L67.6025404,158.048892 C75.6469684,162.693345 85.5581124,162.693345 93.6025404,158.048892 L133.900635,134.782776 C141.945063,130.138324 146.900635,121.555021 146.900635,112.266116 L146.900635,65.7338842 C146.900635,56.4449788 141.945063,47.8616763 133.900635,43.2172237 L93.6025404,19.9511078 Z'
                    />
                    <path
                      css={classes.polygon}
                      strokeWidth='3'
                      d='M93.3525404,34.5847371 C85.4628129,30.0296009 75.7422678,30.0296009 67.8525404,34.5847371 L39.8525404,50.7505447 C31.9628129,55.3056809 27.1025404,63.7239199 27.1025404,72.8341925 L27.1025404,105.165808 C27.1025404,114.27608 31.9628129,122.694319 39.8525404,127.249455 L67.8525404,143.415263 C75.7422678,147.970399 85.4628129,147.970399 93.3525404,143.415263 L121.35254,127.249455 C129.242268,122.694319 134.10254,114.27608 134.10254,105.165808 L134.10254,72.8341925 C134.10254,63.7239199 129.242268,55.3056809 121.35254,50.7505447 L93.3525404,34.5847371 Z'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      ) : null}
    </div>
  );
};

const IconInfoState = ({ status, value }: IStatusIcon) => {
  const { classes } = useStyles(status);
  return (
    <Fragment>
      {value !== null ? <span css={classes.statusText}>{String(value)}</span> : null}
    </Fragment>
  );
};

const IconOkState = ({ status, value }: IStatusIcon) => {
  const { classes } = useStyles(status);
  return (
    <Fragment>
      {value ? (
        <span css={classes.statusText}>{String(value)}</span>
      ) : (
        <span css={[classes.statusText, classes.check]}>
          <Icon name='check' />
        </span>
      )}
    </Fragment>
  );
};

const IconErrorState = ({ status, value }: IStatusIcon) => {
  const { classes } = useStyles(status);
  return (
    <Fragment>{value ? <span css={classes.statusText}>{String(value)}</span> : null}</Fragment>
  );
};

const IconReloadState = ({ status }: IStatusIcon) => {
  const { classes } = useStyles(status);
  return (
    <span css={[classes.statusText, classes.reload]}>
      <Icon name='reload' />
    </span>
  );
};

const IconFailState = ({ status }: IStatusIcon) => {
  const { classes } = useStyles(status);
  return (
    <span css={[classes.statusText, classes.reload]}>
      <Icon name='question' />
    </span>
  );
};

const IconMaliciousState = ({ status }: IStatusIcon) => {
  const { classes } = useStyles(status);
  return (
    <span css={[classes.malicious]}>
      <Icon name='malicious' />
    </span>
  );
};

const useStyles = makeStyles((status: IStatusIcon['status']) => {
  const statusColor = (infoColor: string) => {
    if (status === 'info') {
      return infoColor;
    } else if (status === 'ok') {
      return styles.color.medGreen;
    } else if (status === 'fail') {
      return styles.color.xLightGrey;
    }
    return styles.color.red;
  };

  const statusLight = statusColor(styles.color.purple);
  const statusDark = statusColor(styles.color.lightBlue);

  return {
    base: {
      root: {
        display: 'inline-block',
        position: 'relative',
        fontSize: '1rem',
        maxWidth: '16.2em',
      },
      mt5: {
        marginTop: '5rem',
      },
      icon: {
        width: '100%',
      },
      outerLine: {
        opacity: 0.25,
      },
      innerLine: {
        opacity: 0.5,
      },
      malicious: {
        display: 'block',
        fontSize: '3.9em',
        marginBottom: '3rem',
        marginTop: '2rem',
        color: styles.color.red,
      },
      statusText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: styles.color.white,
        fontWeight: styles.font.weight.black,
        fontSize: '4.6em',
        display: 'block',
        lineHeight: 1,
      },
      check: {
        fontSize: '1.2em',
        top: '50%',
      },
      reload: {
        fontSize: '1.9em',
        top: '50%',
      },
    },
    light: {
      outerLine: {
        stroke: statusLight,
      },
      innerLine: {
        stroke: statusLight,
      },
      polygon: {
        stroke: statusLight,
        fill: statusLight,
      },
    },
    dark: {
      outerLine: {
        stroke: statusDark,
      },
      innerLine: {
        stroke: statusDark,
      },
      polygon: {
        stroke: statusDark,
        fill: 'none',
      },
    },
  };
});

export default StatusIcon;
