import React from 'react';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import styled from '@emotion/styled';
import { Route, Switch } from 'react-router-dom';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Artifact, ESubmissionResultType } from 'models/Submission';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import joinUrl from 'utils/joinUrl';
import Card from 'views/components/layout/Card';
import Panel from 'views/components/layout/Panel';
import DetectionsTab from './ScanResultsTabs/DetectionsTab';
import FileDetailsTab from './ScanResultsTabs/FileDetailsTab';
import JSONTab from './ScanResultsTabs/JSONTab';
import Icon from 'views/components/Icon';
import { parseSandboxURL } from 'views/url';

interface IScanResultsPanelProps {
  file: Artifact | null;
  uuid: string;
  sha256: string;
  isScanning: boolean;
  isLoading?: boolean;
  totalScans?: number;
  onRescan: () => void;
  onRetryScan: () => void;
  onRetryFileDetails: () => void;
}

const messages = {
  detections: 'Detections',
  fileDetails: 'File Details',
  urlDetails: 'URL Details',
  json: 'JSON',
  network: 'Network',
  sandbox: 'Sandbox',
};

const Link = styled.a`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  font-weight: 600;
  font-size: 1.5rem;

  & .icon {
    font-size: 0.7rem;
  }
`;

const ScanResultsPanel = ({
  file,
  sha256,
  uuid,
  isScanning,
  isLoading,
  onRescan,
  totalScans,
  onRetryScan,
  onRetryFileDetails,
}: IScanResultsPanelProps) => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<{ artifactType: string; submissionId: string; uuid: string }>();

  // @ts-ignore
  const urlPrams = [match.params.artifactType, match.params.uuid, match.params.submissionId]
    .filter((i) => i !== undefined)
    .join('/');
  const baseUrl = `/scan/results/${urlPrams}`;

  const urls = {
    detections: baseUrl,
    details: joinUrl(baseUrl, 'details'),
    json: joinUrl(baseUrl, 'json'),
  };

  const _getDetailsMessage = (type: keyof typeof ESubmissionResultType) =>
    type === 'URL' ? messages.urlDetails : messages.fileDetails;

  const _handleChange = (_: React.ChangeEvent<any>, pathname: string) =>
    history.push(`${pathname}${location?.search ?? ''}`);

  const isSandboxLinkEnabled =
    match?.params?.artifactType === 'url' || match?.params?.artifactType === 'file';

  return (
    <Card>
      <Tabs indicatorColor='primary' value={location.pathname} onChange={_handleChange}>
        <Tab
          value={urls.detections}
          label={messages.detections}
          data-testid='scanResultsDetections'
        />
        {file && (
          <Tab
            value={urls.details}
            label={_getDetailsMessage(file.type)}
            data-testid='scanResultsDetails'
          />
        )}
        {file && <Tab value={urls.json} label={messages.json} data-testid='scanResultsJSON' />}
        {isSandboxLinkEnabled && (
          <Link
            className='a'
            href={parseSandboxURL(sha256)}
            onClick={(e) => {
              window.open(parseSandboxURL(sha256), '_blank');
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            Latest Sandbox Results
            <Icon className='icon' name='open-view' />
          </Link>
        )}
      </Tabs>
      <Panel>
        <Switch>
          <Route
            exact
            path={urls.details}
            render={() => (
              <FileDetailsTab
                type={file ? file.type : undefined}
                onRetryFileDetails={onRetryFileDetails}
              />
            )}
          />
          <Route exact path={urls.json} render={() => <JSONTab onRetry={onRetryFileDetails} />} />
          <Route
            path={urls.detections}
            render={() => (
              <DetectionsTab
                isScanning={isScanning}
                file={file}
                uuid={uuid}
                totalScans={totalScans}
                onRetryScan={onRetryScan}
                onRescan={onRescan}
              />
            )}
          />
        </Switch>
      </Panel>
    </Card>
  );
};

export default ScanResultsPanel;
