import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { RootState } from 'state/root';
import { PageQuery } from 'models/Page';
import { useSelector, useDispatch } from 'react-redux';
import { ApiKeyDataRes } from 'services/api/schema/apiKey';
import { extractPageQueryParams } from 'utils/pagination';
import { getApiKeys } from 'state/apiKeys/actions';
import warningImg from 'assets/images/warning.svg';
import PanelContent from 'views/components/layout/PanelContent';
import { useUser } from 'views/components/providers/UserProvider';
import ApiKeyTable from 'views/components/table/ApiKeyTable/ApiKeyTableContainer';

const messages = {
  warningText: 'Account must have 2FA enabled to proceed',
  setup2fa: 'Setup 2FA',
};

const TeamApiKeysTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { apiKeys } = useSelector((state: RootState) => state);
  const teamAccountNumber = useSelector((state: RootState) => state.team.single!.accountNumber);
  const { mfaEnabled } = useUser();

  const _getApiKeys = (refresh: boolean, query?: PageQuery<ApiKeyDataRes>) => {
    const params = Object.assign({}, extractPageQueryParams(apiKeys), query, {});
    dispatch(getApiKeys(params, refresh));
  };

  useEffect(() => {
    if (mfaEnabled) {
      _getApiKeys(true, { offset: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!mfaEnabled) {
    return (
      <PanelContent
        heading={''}
        image={{
          src: warningImg,
          alt: messages.warningText,
        }}
        text={messages.warningText}
        customButtons={[
          <Button
            component='button'
            color='primary'
            variant='contained'
            onClick={() => history.replace('advanced')}
          >
            {messages.setup2fa}
          </Button>,
        ]}
      />
    );
  }
  const hasExternalKeys = apiKeys.results?.find((k => k.type ===  'external'));
  return (
    <div style={{ padding: '0px 3rem 0px 0.5rem' }}>
      <ApiKeyTable
        key='users-table-key'
        isMfaEnabled={mfaEnabled}
        apiKeys={apiKeys}
        externalKeys={false}
        reloadApiKeys={() => _getApiKeys(true, { offset: 0 })}
      />
      <ApiKeyTable
        key='teams-table-key'
        isMfaEnabled={mfaEnabled}
        team={teamAccountNumber}
        apiKeys={apiKeys}
        externalKeys={false}
        reloadApiKeys={() => _getApiKeys(true, { offset: 0 })}
      />
      {hasExternalKeys && (
        <ApiKeyTable
          team
          externalKeys={true}
          isMfaEnabled={mfaEnabled}
          apiKeys={apiKeys}
          reloadApiKeys={() => _getApiKeys(true, { offset: 0 })}
        />
      )}
    </div>
  );
};

export default TeamApiKeysTab;
