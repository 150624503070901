import React, { createContext, useContext, useEffect, useState } from 'react';
import api from 'services/api';
import useIsOwnerOrAdmin from './useIsOwnerOrAdmin';
import useTeamHasFeature from './useTeamHasFeature';
import useNotification from './useNotification';

interface TeamMemberAccessContextType {
  teamMemberAccess: boolean;
  hasAccess?: boolean;
  toggleAccess: (access: boolean) => void;
  loading: boolean;
}

const TeamMemberAccessContext = createContext<TeamMemberAccessContextType | undefined>(undefined);
const Provider = TeamMemberAccessContext.Provider;

export const useTeamMemberAccessContext = () => {
  const context = useContext(TeamMemberAccessContext);
  if (!context) {
    throw new Error('useTeamMemberAccessContext must be used within a TeamMemberAccessProvider');
  }
  return context;
};

export const TeamMemberAccessProvider = ({ children }: { children: React.ReactNode }) => {
  const isOwnerOrAdmin = useIsOwnerOrAdmin();
  const { hasPermission: canViewTeamMembers } = useTeamHasFeature('can_view_team_members');
  const [teamMemberAccess, setTeamMemberAccess] = useState<boolean | null>(null);
  const notification = useNotification();

  const toggleAccess = async (access: boolean) => {
    try {
      const data = await api.setFeature({
        can_view_team_members: access,
      });

      if (data.status === 200) {
        setTeamMemberAccess(access);
        notification.success('Team member access updated');
      } else {
        throw new Error('Failed to update team member access');
      }
    } catch (error) {
      notification.failure('There was an error updating team member access');
    }
  };

  useEffect(() => {
    if (teamMemberAccess === null) {
      if (canViewTeamMembers) {
        setTeamMemberAccess(true);
      } else {
        setTeamMemberAccess(false);
      }
    } else if (!teamMemberAccess && canViewTeamMembers) {
      setTeamMemberAccess(true);
    }
  }, [teamMemberAccess, canViewTeamMembers]);

  return (
    <Provider
      value={{
        teamMemberAccess: Boolean(teamMemberAccess),
        hasAccess: isOwnerOrAdmin,
        toggleAccess,
        loading: teamMemberAccess === null,
      }}
    >
      {children}
    </Provider>
  );
};

export default function useTeamMemberAccess() {
  return useTeamMemberAccessContext();
}
