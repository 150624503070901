import React, { useEffect } from 'react';
import MySandboxingTableContainer from './MySandboxingTable/MySandboxingTableContainer';
import { FilterProps } from '../../SandboxFilter';

type Filter = {
  key: string;
  value: string;
};

const defaultFiltersMySandboxing = {
  startDate: {
    option: 'DAYS',
    value: 30,
  },
  status: 'Any',
  endDate: {
    option: 'TODAY',
  },
  sandboxProvider: 'All',
} as Partial<FilterProps>;

const initialFiltersMySandboxing = {
  startDate: {
    option: 'DAYS',
    value: 30,
  },
} as Partial<FilterProps>;

function MySandboxingTab({
  setFilters,
  removeFilter,
  filters,
  toggleFilters,
  useMySandboxingProps,
  paginationProps,
}: {
  setFilters: (initialState: Partial<FilterProps>) => void;
  removeFilter: (key: keyof FilterProps, defaultValue: any) => void;
  filters: Filter[];
  toggleFilters: () => void;
  useMySandboxingProps: any;
  paginationProps: { [key: string]: any };
}) {
  useEffect(() => {
    setFilters(initialFiltersMySandboxing);
    useMySandboxingProps.refetch([], false, true);
  }, []); // eslint-disable-line

  return (
    <MySandboxingTableContainer
      filters={filters.map((filter) => {
        const obj = defaultFiltersMySandboxing[filter.key as keyof FilterProps];
        return {
          key: filter.key,
          value: filter.value,
          isDefault: obj
            ? typeof obj === 'string'
              ? obj === filter.value
              : typeof obj === 'object'
              ? obj.option === 'TODAY' && filter.value === 'Today'
                ? true
                : obj.value + ' ' + obj.option.toLowerCase() === filter.value
              : false
            : false,
        };
      })}
      removeFilter={(key) => {
        removeFilter(key, defaultFiltersMySandboxing[key as keyof FilterProps]);
      }}
      toggleFilters={toggleFilters}
      useMySandboxingProps={useMySandboxingProps}
      paginationProps={paginationProps}
    />
  );
}

export default MySandboxingTab;
