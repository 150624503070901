import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-chevron-down' viewBox='0 0 16 16'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.91946 7.5C7.02856 7.5 6.58239 8.57714 7.21236 9.20711L10.1517 12.1464C10.347 12.3417 10.6635 12.3417 10.8588 12.1464L13.7981 9.20711C14.4281 8.57714 13.9819 7.5 13.091 7.5L7.91946 7.5Z'
      />
    </symbol>

    <Global
      styles={css`
        .icon-chevron-down {
          width: 3.1em;
          height: 3.1em;
        }
      `}
    />
  </Fragment>
);
