import React, { useMemo, useCallback, useState, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import qs from 'query-string';
import reduce from 'lodash/reduce';
import omit from 'lodash/omit';
import Tabs from '@material-ui/core/Tabs';
import PageLayout from 'views/components/layout/PageLayout';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'state/root';
import { useIntl, defineMessages } from 'react-intl';
import { Switch, Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { getTeamByAccount, getTeamAccount } from 'state/team/actions';
import joinUrl from 'utils/joinUrl';
import useMySandboxing from './SandboxTabs/MySandboxingTab/MySandboxingTable/hooks';
import useAllSandboxing from './SandboxTabs/AllSandboxingTab/AllSandboxingTable/hooks';
import usePagination from 'hooks/usePagination';
import { SandboxingRes } from 'services/api/schema/sandboxing';
import Card from 'views/components/layout/Card';
import Panel from 'views/components/layout/Panel';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import MySandboxingTab from './SandboxTabs/MySandboxingTab';
import AllSandboxingTab from './SandboxTabs/AllSandboxingTab';
import { ActionButtons } from './ActionButtons';
import { FilterProps } from './SandboxFilter';
import PanelLoader from 'views/components/Loader/PanelLoader';
import { Dispatch } from 'state/types/thunk';
import { closeModal, openModal } from 'state/modal/actions';
import { useAuth } from 'views/components/providers/AuthProvider';
import useHasFeature from 'hooks/useHasFeature';
import { contextAccount } from 'state/auth/selectors';
import { store } from 'state/store';
import { Route, Tab } from 'tenants/components';
import { useIsTabEnabled } from 'hooks/useIsTabEnabled';

type Filter = {
  key: string;
  value: string;
};

const messages = defineMessages({
  mySandboxing: {
    id: 'sandboxPanel.mySandboxing',
    defaultMessage: 'My Sandboxing',
  },
  allSandboxing: {
    id: 'sandboxPanel.allSandboxing',
    defaultMessage: 'All Sandboxing',
  },
});

const getFilterTags = (
  filters: Partial<FilterProps> | undefined,
  exclude: Array<keyof FilterProps> = []
) => {
  return Object.keys(filters || {})
    .filter((key) => !exclude.includes(key as keyof FilterProps))
    .map((key) => {
      const value = filters![key as keyof FilterProps]!;

      if (typeof value !== 'string') {
        return {
          key,
          value: value.option === 'DAYS' ? `${value.value} days` : 'Today',
        };
      }

      return {
        key,
        value,
      };
    });
};

const transformFiltersToParams = (filters: Partial<FilterProps>) =>
  reduce(
    filters,
    (acc, val, key) => {
      if (['endDate', 'startDate'].includes(key)) {
        const reducedDates = {
          [key === 'endDate' ? 'end_value' : 'start_value']: (val as FilterProps['startDate'])
            .value,
          [key === 'endDate' ? 'end_option' : 'start_option']: (val as FilterProps['startDate'])
            .option,
        };

        return { ...omit(acc, ['endDate', 'startDate']), ...reducedDates };
      }

      return { ...acc, [key]: val };
    },
    {}
  );

const SandboxPanel = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { isAuthenticated } = useAuth();
  const { hasPermission, loading } = useHasFeature('sandbox_search');
  const { hasPermission: hasSandboxRequestFeature } = useHasFeature('sandbox_request');
  const ctx = contextAccount(store);
  const { classes } = useStyles();
  const tab = useIsTabEnabled();
  const dispatch = useDispatch<Dispatch>();
  const { single: team } = useSelector(({ team }: RootState) => team);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<Partial<FilterProps>>({});
  const accessForbidden = !isAuthenticated
    ? 'notLoggedIn'
    : !hasPermission || !hasSandboxRequestFeature
    ? 'notAllowed'
    : undefined;
  const urls = {
    mySandboxing: joinUrl(match.url, 'my-sandboxing'),
    allSandboxing: joinUrl(match.url, 'all-sandboxing'),
  };

  const mySandboxTagFilters = useMemo(() => {
    const currentMappedFiltersToParams = transformFiltersToParams(filters);

    const params = qs.parse(location.search);
    const paramsStringified = qs.stringify(params);
    if (!!location.search && qs.stringify(currentMappedFiltersToParams) !== paramsStringified) {
      const paramsToFilters = {
        ...omit(params, ['end_value', 'end_option', 'start_value', 'start_option']),
        endDate: { value: params.end_value, option: params.end_option },
        startDate: { value: params.start_value, option: params.start_option },
      };

      setFilters(paramsToFilters);
      return getFilterTags(filters);
    }

    return getFilterTags(filters);
  }, [filters, location.search]);

  const allSandboxTagFilters = useMemo(() => {
    const currentMappedFiltersToParams = transformFiltersToParams(filters);

    const params = qs.parse(location.search);
    const paramsStringified = qs.stringify(params);
    if (!!location.search && qs.stringify(currentMappedFiltersToParams) !== paramsStringified) {
      const paramsToFilters = {
        ...omit(params, ['end_value', 'end_option', 'start_value', 'start_option']),
        endDate: { value: params.end_value, option: params.end_option },
        startDate: { value: params.start_value, option: params.start_option },
      };

      setFilters(paramsToFilters);
      return getFilterTags(filters, ['triggeredBy']);
    }

    return getFilterTags(filters, ['triggeredBy']);
  }, [location.search, filters]);

  const useMySandboxingProps: {
    data: SandboxingRes[] | null;
    error: boolean | string;
    more: boolean;
    refetch: (filters?: Filter[], fetchOffset?: boolean) => void;
    setAccess: (access: boolean) => void;
    loading: boolean;
  } = useMySandboxing(
    urls.mySandboxing === location.pathname ? mySandboxTagFilters : [],
    isAuthenticated && hasPermission && hasSandboxRequestFeature
  );
  const mySandboxPaginationProps = usePagination({
    defaultTotal: useMySandboxingProps.data?.length ?? 0,
    defaultOffset: 0,
    defaultLimit: 50,
  });

  const useAllSandboxingProps: {
    data: SandboxingRes[] | null;
    error: boolean | string;
    more: boolean;
    refetch: (filters?: Filter[], fetchOffset?: boolean) => void;
    refetchItem: (sha256: string, id: string) => void;
    setAccess: (access: boolean) => void;
    loading: boolean;
    firstFetch: boolean;
  } = useAllSandboxing(
    urls.allSandboxing === location.pathname ? allSandboxTagFilters : [],
    isAuthenticated && hasPermission && hasSandboxRequestFeature
  );

  const removeFilter = useCallback(
    (key: keyof FilterProps, defaultValue?: any) => {
      const newFilters = { ...filters };

      if (defaultValue) {
        newFilters[key] = defaultValue;
      } else {
        delete newFilters[key];
      }

      setFilters(newFilters);

      const sandboxType = location.pathname === urls.mySandboxing ? 'my' : 'all';

      if (sandboxType === 'my') {
        useMySandboxingProps.refetch(getFilterTags(newFilters));
      }
      if (sandboxType === 'all') {
        useAllSandboxingProps.refetch(getFilterTags(newFilters));
      }

      const sha256 = qs.parse(location.search)?.hash;
      const mappedFiltersToParams = transformFiltersToParams(newFilters);
      const params = qs.stringify(
        Object.assign({}, mappedFiltersToParams, !!sha256 ? { sha256 } : {})
      );
      history.push(`${location.pathname}?${params}`);
    },
    [
      useAllSandboxingProps,
      urls.mySandboxing,
      filters,
      useMySandboxingProps,
      history,
      location.pathname,
      location.search,
    ]
  );

  const MySandboxingTabComponent = useCallback(() => {
    return (
      <MySandboxingTab
        removeFilter={(key, defaultValue) => {
          removeFilter(key, defaultValue);
        }}
        paginationProps={mySandboxPaginationProps}
        setFilters={setFilters}
        useMySandboxingProps={useMySandboxingProps}
        filters={mySandboxTagFilters}
        toggleFilters={() => setIsFilterOpen(!isFilterOpen)}
      />
    );
  }, [
    mySandboxPaginationProps,
    useMySandboxingProps,
    mySandboxTagFilters,
    removeFilter,
    isFilterOpen,
  ]);

  const AllSandboxingTabComponent = useCallback(() => {
    return (
      <AllSandboxingTab
        filters={allSandboxTagFilters}
        setFilters={setFilters}
        removeFilter={removeFilter}
        toggleFilters={() => setIsFilterOpen(!isFilterOpen)}
        useAllSandboxingProps={useAllSandboxingProps}
      />
    );
  }, [useAllSandboxingProps, isFilterOpen, allSandboxTagFilters, removeFilter]);

  const dispatchCloseModal = () => dispatch(closeModal());
  const showModal = () =>
    dispatch(
      openModal('SUBMIT_TO_SANDBOX_MODAL', {
        onSubmit: () => {
          const newFilters = {
            ...filters,
            last_updated: new Date().toISOString(),
          };

          useMySandboxingProps.refetch(getFilterTags(newFilters), false);
          setFilters(newFilters);
          dispatchCloseModal();
        },
      })
    );

  const _handleChange = (_: React.ChangeEvent<any>, pathname: string) => {
    history.push(pathname);
    setIsFilterOpen(false);
  };

  useEffect(() => {
    (async () => {
      if (ctx?.context === 'team' && String(ctx?.accountNumber) !== String(team?.accountNumber)) {
        await dispatch(getTeamByAccount(ctx.accountNumber));
        dispatch(getTeamAccount(ctx.accountNumber));
      }
    })();
  }, [ctx?.accountNumber, ctx?.context, dispatch, team?.accountNumber]);

  if (loading) {
    return (
      <Card>
        <Panel>
          <PanelLoader />
        </Panel>
      </Card>
    );
  }

  const onSandboxFiltersApply = (filters: Partial<FilterProps>, type: 'my' | 'all') => {
    setFilters(filters as typeof filters);

    const mappedFiltersToParams = transformFiltersToParams(filters);

    if (type === 'my') {
      const mySandboxFilters = getFilterTags(filters);
      useMySandboxingProps.refetch(mySandboxFilters, false);
    }

    const sha256 = qs.parse(location.search)?.sha256 && qs.parse(location.search)?.hash;

    if (type === 'all') {
      const allSandboxFilters = getFilterTags(Object.assign({}, filters, !!sha256 && { sha256 }));
      useAllSandboxingProps.refetch(allSandboxFilters, false);
    }

    const params = qs.stringify(
      Object.assign({}, mappedFiltersToParams, !!sha256 ? { sha256 } : {})
    );
    history.push(`${location.pathname}?${params}`);
  };

  return (
    <PageLayout>
      <Card>
        <Tabs
          css={classes.tabs}
          indicatorColor='primary'
          value={
            location.pathname === '/sandbox'
              ? !isAuthenticated || !hasPermission || !hasSandboxRequestFeature
                ? urls.allSandboxing
                : urls.mySandboxing
              : location.pathname
          }
          onChange={_handleChange}
        >
          <Tab
            value={urls.mySandboxing}
            show={
              isAuthenticated &&
              hasPermission &&
              hasSandboxRequestFeature &&
              tab.isEnabled('sandbox', 'mySandboxing')
            }
            label={intl.formatMessage(messages.mySandboxing)}
          />
          <Tab
            value={urls.allSandboxing}
            show={tab.isEnabled('sandbox', 'allSandboxing')}
            label={intl.formatMessage(messages.allSandboxing)}
          />
        </Tabs>
        <ActionButtons
          filters={filters}
          filterOpen={isFilterOpen}
          onFilterOpen={() => setIsFilterOpen(true)}
          onFilterClose={() => setIsFilterOpen(false)}
          disabled={!!accessForbidden}
          active={location.pathname === urls.mySandboxing ? 'my' : 'all'}
          onSubmitToSandbox={showModal}
          onFilterApply={onSandboxFiltersApply}
        />
        <Panel>
          <Switch>
            <Route
              exact
              path={urls.mySandboxing}
              show={
                isAuthenticated &&
                hasPermission &&
                hasSandboxRequestFeature &&
                tab.isEnabled('sandbox', 'mySandboxing')
              }
              render={MySandboxingTabComponent}
            />
            <Route
              exact
              path={urls.allSandboxing}
              show={tab.isEnabled('sandbox', 'allSandboxing')}
              render={AllSandboxingTabComponent}
            />
            <Redirect
              exact
              from={match.url}
              to={tab.defaulTab(
                'sandbox',
                urls,
                !isAuthenticated || !hasPermission || !hasSandboxRequestFeature
                  ? 'allSandboxing'
                  : 'mySandboxing'
              )}
            />
            <Redirect to='/404' />
          </Switch>
        </Panel>
      </Card>
    </PageLayout>
  );
};

const useStyles = makeStyles({
  base: {
    tabs: {
      paddingTop: '4rem !important',
    },
  },
  light: {},
  dark: {},
});

export default SandboxPanel;
