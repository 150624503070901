import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles as makeMuiStyles } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import { IIcon } from 'views/components/Icon';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { UserAvatar, TeamAvatar } from 'views/components/Avatar';
import UserMenuItem from './UserMenuItem';

interface IUserMenu {
  username: string;
  name: string;
  teamName?: string | null;
  accountNumber: string;
  items: MenuOption[];
  externalItems: MenuOption[];
  actionsMenuItems: MenuOption[];
  communityMode?: 'Public' | 'Private';
  showCommunityMode?: boolean;
}

export interface MenuOption {
  id: string;
  iconName?: IIcon['name'];
  teamName?: string;
  username?: string;
  accountNumber: string;
  label?: string;
  href?: string;
  onClick?: (...args: any[]) => void;
  items?: MenuOption[];
  isPrivate: boolean;
  isShown?: boolean;
  spacer?: boolean;
  dataCy?: string;
  isActive?: boolean;
}

const UserMenu = ({
  accountNumber,
  username,
  name,
  teamName,
  items,
  externalItems,
  actionsMenuItems,
  showCommunityMode,
  communityMode,
}: IUserMenu) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { classes } = useStyles();
  const muiClasses = useMuiStyles();

  const _handleOpen = (e: React.MouseEvent<any>) => setAnchorEl(e.currentTarget);

  const _handleClose = () => setAnchorEl(null);

  const _handleClick = (onItemClick?: () => void) => {
    onItemClick && onItemClick();
    _handleClose();
  };

  const renderItem = (item: MenuOption) => {
    if (item.isShown === false) {
      return null;
    }
    return <UserMenuItem key={item.id} {...item} onClick={() => _handleClick(item.onClick)} />;
  };

  return (
    <div css={classes.root}>
      <button
        aria-haspopup='true'
        css={classes.toggle}
        onClick={_handleOpen}
        data-testid='userMenu'
      >
        <p css={classes.userName}>
          {teamName || username} {showCommunityMode && communityMode ? `- ${communityMode}` : ''}
        </p>
        {teamName ? (
          <TeamAvatar
            isPrivate={!!communityMode && communityMode.toLowerCase() === 'private'}
            css={classes.avatar}
            accountNumber={`${accountNumber}`}
            teamName={teamName}
          />
        ) : (
          <UserAvatar css={classes.avatar} name={name} accountNumber={`${accountNumber}`} />
        )}
      </button>
      <Menu
        id='user-menu'
        classes={{ paper: muiClasses.paper }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={_handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items.slice(0, 1).map(renderItem)}
        <span css={classes.categoryTitle}>Teams</span>
        {items.slice(1).map(renderItem)}
        {externalItems.length > 0 && <span css={classes.categoryTitle}>Limited Access</span>}
        {externalItems.map(renderItem)}
        {actionsMenuItems.map(renderItem)}
      </Menu>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      display: 'inline-block',
      position: 'relative',
    },
    toggle: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      transition: `color ${styles.easing.time} ${styles.easing.main}`,
    },
    userName: {
      fontSize: styles.font.size.p1,
      fontWeight: styles.font.weight.medium,
      marginRight: styles.spacing.xxs,
    },
    avatar: {
      height: '4em !important',
      width: '4em !important',
    },
    categoryTitle: {
      fontSize: '1.5rem',
      fontWeight: 600,
      marginLeft: '2.8rem',
      display: 'block',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
  },
  light: {
    toggle: {
      color: styles.color.black,
      '&:hover, &:focus': {
        color: styles.color.purple,
      },
      '&:active': {
        color: styles.color.medPurple,
      },
    },
  },
  dark: {
    toggle: {
      color: styles.color.white,
      '&:hover, &:focus': {
        color: styles.color.lightBlue,
      },
      '&:active': {
        color: styles.color.xLightBlue,
      },
    },
  },
});

const useMuiStyles = makeMuiStyles({
  paper: {
    minWidth: '21.2rem',
    width: 'auto',
    borderRadius: '1rem',
  },
});

export default UserMenu;
