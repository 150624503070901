import { createInitialPagination, buildPaginatedData } from 'utils/pagination';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import assign from 'lodash/assign';
import { ApiKeyData } from 'models/ApiKey';
import { ApiKeysAction, ApiKeysActionName } from './types';
import { ApiKeysState } from '.';

export const initialApiKeyPagination = createInitialPagination<ApiKeyData>({
  direction: 'desc',
});

export const initialState: ApiKeysState = initialApiKeyPagination;

type configData = { config: any };

const apiKey = (state: ApiKeysState = initialState, action: ApiKeysAction): ApiKeysState => {
  switch (action.type) {
    case ApiKeysActionName.REFRESH_API_KEYS: {
      return action.result ? buildPaginatedData(action.result, action.result.results) : state;
    }

    case ApiKeysActionName.GET_API_KEYS: {
      if (!action.result) {
        return state;
      } else {
        const result = buildPaginatedData(action.result, action.result.results);
        const stateResults = state.results ?? [];
        return {
          ...result,
          results: uniqBy([...stateResults, ...result.results], 'id'),
        };
      }
    }

    case ApiKeysActionName.EDIT_TAGS_API_KEY: {
      if (!action.result) {
        return state;
      }

      const config = (action?.result as unknown as configData)?.config;

      const urlSplit = config.url.split('/') ?? [];
      const keyId = !isEmpty(urlSplit) && (urlSplit[urlSplit.length - 1] as string);
      const data = JSON.parse(config.data ?? '{}');
      return {
        ...state,
        results: state.results?.map((item) => {
          const internalTag = item?.tags?.filter((tag) => tag.includes('team:')) ?? [];
          if (item.id === keyId) {
            return assign(item, {
              tags: ([] as string[]).concat(internalTag, data?.tags ?? []),
            });
          }

          return item;
        }),
      };
    }

    case ApiKeysActionName.CLEAR_API_KEYS_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
export default apiKey;
