import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from 'views/components/providers/AuthProvider';
import { useUser } from 'views/components/providers/UserProvider';
import { MultifactorAuthModalParams } from './modals/MultifactorAuthModal';
import { getMFAData, removeMFAData } from 'state/auth/actions';
import { openModal } from 'state/modal/actions';
import AdvancedSettings from './AdvancedSettings';
import useHasFeature from 'hooks/useHasFeature';
import { setUserFeature } from 'state/user/actions';

const AdvancedTab = () => {
  const dispatch = useDispatch();
  const { isPasswordUser } = useAuth();
  const { mfaEnabled } = useUser();
  const { hasFeature: isNotificationsEnabled } = useHasFeature('has_notifications');

  useEffect(() => {
    const mfaStep = getMFAData();
    if (mfaStep) {
      const { modal } = mfaStep;
      if (modal === 'REMOVE_MULTIFACTOR_AUTH') {
        dispatch(openModal(modal));
        removeMFAData();
      }
    }
  });

  const _handleMfaToggle = (_: React.ChangeEvent, mfaEnabled: boolean) =>
    dispatch(
      openModal<MultifactorAuthModalParams>('MULTIFACTOR_AUTH', { mfaEnabled })
    );

  const _handleNotificationsToggle = (_: React.ChangeEvent, notificationsEnabled: boolean) =>
    dispatch(setUserFeature('has_notifications', notificationsEnabled))

  const _handleArchiveUser = () => dispatch(openModal('ARCHIVE_USER'));

  return (
    <AdvancedSettings
      isPasswordUser={isPasswordUser}
      isMfaEnabled={mfaEnabled}
      isNotificationsEnabled={isNotificationsEnabled}
      onMfaToggle={_handleMfaToggle}
      onNotificationsToggle={_handleNotificationsToggle}
      onArchiveUser={_handleArchiveUser}
    />
  );
};

export default AdvancedTab;
