import { useUser } from 'views/components/providers/UserProvider';
import { ETeamRole } from 'models/Team';

export default function useIsOwnerOrAdmin() {
  const user = useUser();

  if (user?.context?.team) {
    const accountNumber = user.context.accountNumber;
    const team = user.teams.find((team) => team.accountNumber === accountNumber);
    return (
      (team && team.roles.includes(ETeamRole.teamOwner)) ||
      (team && team.roles.includes(ETeamRole.teamAdmin))
    );
  } else {
    return false;
  }
}
