import api from 'services/api';
import request from 'state/utils/request';
import { Thunk } from 'state/types/thunk';
import { User } from 'models/User';
import { UserActionName, SetUserAction } from './types';
import { loginRemoveMfa, loginWriteMfa, logout } from 'state/auth/actions';
import { TimeSeriesAttributes } from 'models/TimeSeries';
import { closeModal } from 'state/modal/actions';
import { AccountRes } from 'services/api/schema/account';
import { UserStatsRes, UserRes } from 'services/api/schema/user';
import { showNotification } from '../notification/actions';

export const setUser = (user: User): SetUserAction => ({
  type: UserActionName.SET_USER,
  result: user,
});

export const getSelf = () =>
  request(() => api.getSelf().then((res) => res.data), {
    type: UserActionName.GET_USER,
  });

export const getUserAccount =
  (refresh = true): Thunk<Promise<AccountRes>> =>
  (dispatch, getState) => {
    const { accountNumber } = getState().user.profile!;
    return dispatch(
      request(() => api.getAccount(accountNumber).then((res) => res.data), {
        type: refresh ? UserActionName.REFRESH_USER_ACCOUNT : UserActionName.GET_USER_ACCOUNT,
      })
    );
  };

export const getUserUsage =
  (accountNumber?: string | number): Thunk<Promise<AccountRes>> =>
  (dispatch, getState) => {
    let account: string | number;
    if (accountNumber) {
      account = accountNumber;
    } else {
      account = getState().user.profile!.accountNumber;
    }
    return dispatch(
      request(() => api.getAccount(account).then((res) => res.data), {
        type: UserActionName.GET_USER_USAGE,
      })
    );
  };

export const getUserStats =
  (params?: TimeSeriesAttributes): Thunk<Promise<UserStatsRes>> =>
  (dispatch, getState) => {
    const userId = getState().user.profile?.id!;
    return dispatch(
      request(() => api.getUserStats(userId, params).then((res) => res.data), {
        type: UserActionName.GET_USER_STATS,
      })
    );
  };

export const updateUser =
  (userValues: Partial<User>, withNotify?: boolean): Thunk<Promise<UserRes>> =>
  (dispatch, getState) => {
    const userId = getState().user.profile?.id!;
    return dispatch(
      request(
        () => api.updateUser(userId, userValues).then((res) => res.data),
        { type: UserActionName.UPDATE_USER },
        withNotify && {
          success: 'Profile updated',
          failure: 'Error updating profile. Please try again.',
        }
      )
    );
  };

export const setMultifactorAuth =
  (mfaEnabled: boolean, modalName: string, locationPathname: string): Thunk<Promise<void>> =>
  async (dispatch) => {
    if (mfaEnabled) {
      await dispatch(
        request(
          () => api.enableUserMfa().then(() => ({ mfaChanged: true })),
          { type: UserActionName.SET_MULTIFACTOR_AUTH },
          { failure: 'Error updating 2FA settings. Please try again.' }
        )
      );
      await dispatch(loginWriteMfa({ location: locationPathname }));
    } else {
      dispatch(
        loginRemoveMfa({
          modal: modalName,
          location: locationPathname,
        })
      );
    }
  };

export const archiveUser = (): Thunk<Promise<void>> => async (dispatch, getState) => {
  const userId = getState().user.profile?.id!;
  await dispatch(
    request(() => api.archiveUser(userId).then((res) => res.data), {
      type: UserActionName.ARCHIVE_USER,
    })
  );
  dispatch(closeModal());
  dispatch(logout('/'));
};

export const setUserFeature = (featureTag: string, value: any): Thunk<Promise<void>> =>
    async (dispatch, getState) => {

  const { accountNumber } = getState().user.profile!;
  try {
    await api.updateUserFeature(featureTag, value);
    await dispatch(
      request(() => api.getAccount(accountNumber).then((res) => res.data),
        { type: UserActionName.REFRESH_USER_ACCOUNT },
      )
    );
  } catch (error) {
    dispatch(showNotification({
      status: 'failure',
      message: 'Error updating notifications settings. Please try again.'
    }));
  }
};
