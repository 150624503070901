import React, { ReactNode } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

export interface ISetting {
  text: string;
  description?: string;
  isShown?: boolean;
  action: ReactNode;
}

const Setting = ({ text, description, isShown, action }: ISetting) => {
  const { classes } = useStyles();
  return isShown === false ? null : (
    <>
      <div css={classes.main}>
        <p className='p1' css={classes.text}>
          {text}
        </p>
        <div css={classes.control}>{action}</div>
      </div>
      {description &&
        <div css={classes.bottom}>
          <p className='p1' css={classes.description}>
            {description}
          </p>
        </div>
      }
    </>
  );
};

const useStyles = makeStyles({
  base: {
    main: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      maxWidth: '100rem',
      margin: `1rem 0 0 0`,
    },
    bottom: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      maxWidth: '100rem',
      margin: `0.25rem 0 0 0`,
    },
    text: {
      flex: 1.5,
    },
    description: {
      flex: 1.5,
      color: styles.color.lightGrey,
    },
    control: {
      flex: 1,
      marginLeft: styles.spacing.grid,
    },
  },
});

export default Setting;
