import { AsyncAction } from 'state/types/actions';
import {
  AccountPlansRes,
  AccountContextRes,
} from 'services/api/schema/account';

export enum AccountActionName {
  GET_ACCOUNT_PLANS = 'GET_ACCOUNT_PLANS',
  SWITCH_ACCOUNT = 'SWITCH_ACCOUNT',
  GET_ACCOUNT_CONTEXT = 'GET_ACCOUNT_CONTEXT',
}

export interface GetAccountPlansAction
  extends AsyncAction<AccountActionName.GET_ACCOUNT_PLANS, AccountPlansRes> {}

export interface SwitchAccountAction
  extends AsyncAction<AccountActionName.SWITCH_ACCOUNT, AccountContextRes> {}

export interface GetAccountContextAction
  extends AsyncAction<AccountActionName.GET_ACCOUNT_CONTEXT, AccountContextRes> {}

export type AccountAction =
  | GetAccountPlansAction
  | SwitchAccountAction
  | GetAccountContextAction;
