import React from 'react';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Settings from 'views/components/Settings';

interface IAdvancedSettings {
  isPasswordUser?: boolean;
  isMfaEnabled: boolean;
  isNotificationsEnabled: boolean;
  onMfaToggle: (event: React.ChangeEvent, checked: boolean) => void;
  onNotificationsToggle: (event: React.ChangeEvent, checked: boolean) => void;
  onArchiveUser: () => void;
}

const AdvancedSettings = ({
    isMfaEnabled,
    isNotificationsEnabled,
    onMfaToggle,
    onNotificationsToggle,
    onArchiveUser
}: IAdvancedSettings) => {

  return (
    <Settings
      groups={[
        {
          heading: 'Secure Authentication',
          isShown: true,
          settings: [
            {
              text: 'Enable Two-Factor Authentication (2FA) for Secure Login',
              action: <Switch onChange={onMfaToggle} checked={isMfaEnabled} data-cy='mfaBtn' />,
            },
          ],
        },
        {
          heading: 'Notifications',
          isShown: true,
          settings: [
            {
              text: 'Enable email notifications for long-running tasks',
              description: 'You will receive a notification when a Sandbox Task ended (coming soon hunting matches will be included).',
              action: <Switch onChange={onNotificationsToggle} checked={isNotificationsEnabled} data-cy='notifBtn' />,
            },
          ],
        },
        {
          heading: 'Delete Account',
          danger: true,
          settings: [
            {
              text: 'Permanently delete your PolySwarm account and information',
              action: (
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={onArchiveUser}
                  data-cy='archiveUserBtn'
                >
                  Delete Account
                </Button>
              ),
            },
          ],
        },
      ]}
    />
  );
};

export default AdvancedSettings;
