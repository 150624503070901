import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import Tabs from '@material-ui/core/Tabs';
import joinUrl from 'utils/joinUrl';
import Card from 'views/components/layout/Card';
import Panel from 'views/components/layout/Panel';
import HashSearchTab from './SearchTabs/HashSearchTab';
import MetadataSearchTab from './SearchTabs/MetadataSearchTab/MetadataSearchTabContainer';
import IOCSearchTab from './SearchTabs/IOCSearchTab/IOCSearchTab';
import { useIsTabEnabled } from 'hooks/useIsTabEnabled';
import { Route, Tab } from 'tenants/components';

const messages = defineMessages({
  hashSearch: {
    id: 'search.hashSearch',
    defaultMessage: 'Hash Searching',
  },
  metaSearch: {
    id: 'search.metaSearch',
    defaultMessage: 'Metadata Searching',
  },
  ioc: {
    id: 'search.ioc',
    defaultMessage: 'IOC Searching',
  },
});

const SearchPanel = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const tab = useIsTabEnabled();

  const urls = {
    hash: joinUrl(match.url, 'hash'),
    metadata: joinUrl(match.url, 'metadata'),
    ioc: joinUrl(match.url, 'ioc'),
  };

  const _handleChange = (_: React.ChangeEvent<any>, pathname: string) => history.push(pathname);

  return (
    <Card>
      <Tabs
        indicatorColor='primary'
        value={location.pathname === '/search' ? '/search/hash' : location.pathname}
        onChange={_handleChange}
      >
        <Tab
          value={urls.hash}
          label={intl.formatMessage(messages.hashSearch)}
          data-cy='searchHash'
          show={tab.isEnabled('search', 'hash')}
        />
        <Tab
          value={urls.metadata}
          label={intl.formatMessage(messages.metaSearch)}
          data-cy='searchMetadata'
          show={tab.isEnabled('search', 'metadata')}
        />
        <Tab
          value={urls.ioc}
          label={intl.formatMessage(messages.ioc)}
          data-cy='searchIOC'
          show={tab.isEnabled('search', 'ioc')}
        />
      </Tabs>
      <Panel>
        <Switch>
          <Route
            exact
            path={urls.hash}
            show={tab.isEnabled('search', 'hash')}
            component={HashSearchTab}
          />
          <Route
            exact
            path={urls.metadata}
            show={tab.isEnabled('search', 'metadata')}
            component={MetadataSearchTab}
          />
          <Route
            exact
            path={urls.ioc}
            show={tab.isEnabled('search', 'ioc')}
            component={IOCSearchTab}
          />
          <Redirect exact from={match.url} to={tab.defaulTab('search', urls, 'hash')} />
          <Redirect to='/404' />
        </Switch>
      </Panel>
    </Card>
  );
};

export default SearchPanel;
