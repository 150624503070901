import React, { useEffect, useState } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import styles from 'views/styles';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { SandboxProviders } from 'models/Sandbox';
import { ActiveSections, Section } from 'views/components/ActiveSections';
import AccordionData from 'views/components/Accordion/AccordionData';
import { IAccordionData } from 'views/components/Accordion/AccordionData/AccordionData';
import { useLocation } from 'react-router-dom';
import { mapSignatures, mapMitre, mapOSAutostarts, mapOSAutorun } from './mapper';

const checkForPresentValues = (data: Record<string, any> | undefined) => {
  if (!data) return false;
  if (Array.isArray(data)) {
    if (data.length === 0) return false;
    return (
      data.length > 0 &&
      data.some((item) => {
        if (!!item && typeof item === 'object' && 'data' in item) {
          return item.data?.length > 0 || typeof item.data === 'object';
        }
        return Boolean(item);
      })
    );
  } else if (typeof data === 'object') {
    return Object.values(data).some(checkForPresentValues);
  }
  return false;
};

export const getAnalysisData = (data: Record<string, any> | null, provider: SandboxProviders) => {
  const processAttributeName = provider === SandboxProviders.CAPE ? 'cape_filter' : 'processes';

  const analysisData = {
    osAutostarts: mapOSAutostarts(data?.extracted ?? []),
    osAutorun: mapOSAutorun(data?.extracted ?? []),
    mitre: mapMitre(uniq(data?.ttp ?? []), provider),
    processes: [
      !isEmpty(data?.[processAttributeName]) && {
        id: 'processes',
        component: 'table' as const,
        data:
          // @ts-ignore
          data?.[processAttributeName]?.map((item) =>
            pick(
              item,
              provider === SandboxProviders.CAPE
                ? ['pid', 'size', 'sha256', 'process_name', 'process_path']
                : ['cmd', 'pid', 'ppid']
            )
          ) ?? [],
      },
    ].filter(Boolean) as IAccordionData['data'],
    regkey: (() => {
      if (provider === SandboxProviders.TRIAGE) {
        return {
          'extracted.config.attr.regKey': {
            label: 'RegKey',
            value: data?.extracted
              ?.map((item: any) => {
                return item?.config?.attr?.regKey || item?.config?.attr?.reg_key;
              })
              .filter(Boolean),
          },
        };
      }
      if (provider === SandboxProviders.CAPE) {
        return {
          'Read Keys': data?.behavior?.summary?.read_keys ?? [],
          'Write Keys': data?.behavior?.summary?.write_keys ?? [],
          Mutexes: data?.behavior?.summary?.mutexes ?? [],
        };
      }
      return undefined;
    })(),
    yara: (provider === SandboxProviders.TRIAGE && {
      'targets.signatures.indicators.yara_rule': {
        label: 'Yara rules triggered',
        value: data?.targets
          ?.map((target: any) =>
            target?.signatures?.map((signature: any) =>
              signature?.indicators?.map((indicator: any) => indicator?.yara_rule)
            )
          )
          .flat(2)
          .filter(Boolean)
          .reduce((acc: any, curr: any) => {
            if (!acc.includes(curr)) {
              acc.push(curr);
            }
            return acc;
          }, []),
      },
    }) as IAccordionData['data'],
    signatures:
      provider === SandboxProviders.CAPE
        ? [
            data?.signatures && {
              id: 'signatures',
              component: 'table' as const,
              data:
                // @ts-ignore
                data?.signatures?.map((item) => {
                  const {
                    severity = '',
                    data: indicatorData = [],
                    ...items
                  } = pick(item, ['name', 'data', 'description', 'severity']);
                  const data = indicatorData?.map((val: any) => pick(val, 'indicator')) ?? [];
                  const indicator = data
                    .map((item: any) => item?.indicator)
                    .filter(Boolean)
                    .join(', ');

                  return { ...items, indicator: isEmpty(indicator) ? ' - ' : indicator, severity };
                }) ?? [],
            },
          ].filter(Boolean)
        : undefined,
    'static signatures': mapSignatures(data?.static?.signatures, 'Static Signatures'),
    'behavioral signatures': mapSignatures(data?.targets?.[0]?.signatures, 'Behavioral Signatures'),
    files:
      provider === SandboxProviders.CAPE
        ? {
            'Delete File': data?.behavior?.summary?.delete_files ?? [],
            'Read Files': data?.behavior?.summary?.read_files ?? [],
            'Modify Files': data?.behavior?.summary?.write_files ?? [],
          }
        : undefined,
  };
  return { analysisData, hasPresentValues: checkForPresentValues(analysisData) };
};

const AnalysisTab = ({
  dataMapped,
  provider,
}: {
  dataMapped: ReturnType<typeof getAnalysisData>['analysisData'];
  provider: SandboxProviders;
}) => {
  const location = useLocation();
  const providerKey = provider === 'cape' ? 'cape_sandbox_v2' : 'triage_sandbox_v0';

  useEffect(() => {
    if (location.hash) {
      document.querySelector(location.hash)?.scrollIntoView();
    }
  }, [location.hash]);

  const analysisSections = [
    provider === SandboxProviders.TRIAGE && {
      label: 'O',
      title: 'OS Autostarts',
      active: dataMapped.osAutostarts ? true : false,
    },
    provider === SandboxProviders.TRIAGE && {
      label: 'O',
      title: 'OS Autorun',
      active: dataMapped.osAutorun ? true : false,
    },
    {
      label: 'M',
      title: 'MITRE',
      active: checkForPresentValues(dataMapped.mitre),
    },
    provider === SandboxProviders.TRIAGE && {
      label: 'Y',
      title: 'YARA',
      active: checkForPresentValues(dataMapped.yara),
    },
    {
      label: 'R',
      title: 'Registry Keys',
      active: checkForPresentValues(dataMapped.regkey),
    },
    provider === SandboxProviders.CAPE && {
      label: 'S',
      title: 'Signatures',
      active: checkForPresentValues(dataMapped.signatures),
    },
    provider === SandboxProviders.TRIAGE && {
      label: 'SS',
      title: 'Static Signatures',
      active: checkForPresentValues(dataMapped['static signatures']),
    },
    provider === SandboxProviders.TRIAGE && {
      label: 'BS',
      title: 'Behavioral Signatures',
      active: checkForPresentValues(dataMapped['behavioral signatures']),
    },
    {
      label: 'P',
      title: 'Processes',
      active: checkForPresentValues(dataMapped.processes),
    },
    {
      label: 'F',
      title: 'Files',
      active: checkForPresentValues(dataMapped.files),
    },
  ].filter((item) => !isEmpty(item)) as Section[];

  return (
    <div css={style.root}>
      {checkForPresentValues(dataMapped) ? (
        <>
          <ActiveSections sections={analysisSections} />
          {dataMapped.osAutostarts && (
            <AccordionData
              id={`${providerKey}.extracted.config.attr.autostart`}
              key='OS Autostarts'
              css={style.item}
              title='OS Autostarts'
              data={dataMapped.osAutostarts}
              columns={2}
            />
          )}
          {dataMapped.osAutorun && (
            <AccordionData
              id={`${providerKey}.extracted.config.attr.autorun`}
              key='OS Autorun'
              css={style.item}
              title='OS Autorun'
              data={dataMapped.osAutorun}
              columns={2}
            />
          )}
          {checkForPresentValues(dataMapped.mitre) ? (
            <AccordionData
              key='accordion-data-mitre'
              css={style.item}
              title='MITRE'
              helpText='TTP IDs displayed  are Subject to Change, the MITRE Website may redirect you to a different TTP Number, but the contents of that TTP will be the same.'
              data={dataMapped.mitre}
              columns={2}
            />
          ) : null}
          {checkForPresentValues(dataMapped.yara) && (
            <AccordionData
              id={`${providerKey}-yara`}
              key='YARA'
              css={style.item}
              title='YARA'
              data={dataMapped.yara}
              columns={2}
            />
          )}
          {checkForPresentValues(dataMapped.regkey) ? (
            <AccordionData
              key='Registry Keys'
              id={`${providerKey}-registryKeys`}
              css={style.item}
              title='Registry Keys'
              data={dataMapped.regkey}
              columns={2}
            />
          ) : null}
          {checkForPresentValues(dataMapped.signatures) ? (
            <AccordionData
              key='Signatures'
              id={`${providerKey}.targets.signatures`}
              css={style.item}
              title='Signatures'
              data={dataMapped.signatures}
              columns={2}
            />
          ) : null}
          {checkForPresentValues(dataMapped['static signatures']) ? (
            <AccordionData
              key='StaticSignatures'
              id={`${providerKey}.targets.static`}
              css={style.item}
              title='Static Signatures'
              data={dataMapped['static signatures']}
              columns={2}
            />
          ) : null}
          {checkForPresentValues(dataMapped['behavioral signatures']) ? (
            <AccordionData
              key='BehavioralSignatures'
              id={`${providerKey}.targets.behavioral`}
              css={style.item}
              title='Behavioral Signatures'
              data={dataMapped['behavioral signatures']}
              columns={2}
            />
          ) : null}
          {checkForPresentValues(dataMapped.processes) ? (
            <AccordionData
              key='Processes'
              id={`${providerKey}.targets.processes`}
              css={style.item}
              title='Processes'
              data={dataMapped.processes}
              columns={2}
            />
          ) : null}
          {checkForPresentValues(dataMapped.files) ? (
            <AccordionData
              key='Files'
              id={`${providerKey}.behavior.summary`}
              css={style.item}
              title='Files'
              data={dataMapped.files}
              columns={2}
            />
          ) : null}
        </>
      ) : (
        'No Analysis Data'
      )}
    </div>
  );
};

const style = {
  root: css`
    margin: calc(${styles.spacing.grid} / 2) ${styles.spacing.grid};
  `,
  item: css`
    margin-bottom: ${styles.spacing.grid};
    &:last-child {
      margin-bottom: 0;
    }
  `,
};
export default AnalysisTab;
