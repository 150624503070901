import { ContextAccount, parseContextAccount } from '../state/auth/selectors';

/**
 * The URL will look like:
 *
 * - /scan/results/:sha256
 * - /scan/results/file/:sha256
 * - /scan/results/:sha256/:instanceId
 * - ...
 */
export const parseScanPageURL = (
  sha256: string,
  props: { type?: 'url' | 'file'; instanceId?: string; fallbackId?: string } = {}
): string => {
  let url = '/scan/results/';
  if (props.type) {
    url += `${props.type}/`;
  }
  url += sha256;
  if (props.instanceId) {
    url += `/${props.instanceId}`;
  }

  if (props.fallbackId) {
    url += `?fallbackId=${props.fallbackId}`;
  }
  return url;
};

/**
 * The URL will look like:
 *
 * - /sandbox/detail/file/:sha256
 * - /sandbox/detail/file/:sha256?u=1234-private
 */
export const parseSandboxURL = (
  sha256: string,
  props: { section?: string; sandboxId?: string; sandboxType?: string } = {}
): string => {
  let url = `/sandbox/detail/${props.sandboxType?.toLowerCase() ?? 'file'}/${sha256}`;
  if (props.section) {
    url += `/${props.section}`;
  }
  if (props.sandboxId) {
    url += `?sandboxId=${props.sandboxId}`;
  }
  return url;
};

export const parseSwitchURL = (ctx: string | number | ContextAccount, next?: string): string => {
  const account =
    typeof ctx === 'string' || typeof ctx === 'number'
      ? ctx.toString()
      : parseContextAccount(ctx, true);
  let url = `/switch?u=${account}`;
  if (next) {
    url += `&next=${next}`;
  }
  return url;
};
