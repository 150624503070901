import React, { Fragment } from 'react';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import { ScrollToTopOnMount } from 'views/components/ScrollToTop';
import PricingPlans from './PricingPlans/PricingPlans';

export enum ETabName {
 INDIVIDUAL,
 ENTERPRISE,
}

const PricingPage = () => {

  return (
    <Fragment>
      <SEO title="Pricing" />
      <BaseLayout>
        <ScrollToTopOnMount />
        <PricingPlans />
      </BaseLayout>
    </Fragment>
  );
};

export default PricingPage;
