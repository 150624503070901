import api from 'services/api';
import request from 'state/utils/request';
import { Thunk } from 'state/types/thunk';
import { history } from 'state/store';
import { AccountActionName } from './types';
import { showNotification } from 'state/notification/actions';
import { ErrorKeys, errorMessages, reduxErrorBanner } from 'utils/error';
import { getTeamAccount } from 'state/team/actions';
import { ContextAccount, setLastContextAccount } from 'state/auth/selectors';
import { parseSwitchURL } from '../../views/url';

const errorKeys: ErrorKeys = {
  api_key_not_found_for_account: errorMessages.noApiKeys.id,
};

export const getAccountPlans = () =>
  // @ts-ignore
  request(() => api.getAccountPlans().then((res) => res.data), {
    type: AccountActionName.GET_ACCOUNT_PLANS,
  });

export const getAccountContext =
  (ctx?: ContextAccount): Thunk<Promise<void>> =>
  async (dispatch) => {
    return api
      .getAccountContext(ctx)
      .then((res) => res.data)
      .then((result) => {
        // Hack to remember last session when the user comes back
        // from the same device / browser
        setLastContextAccount({
          accountNumber: result.accountNumber,
          context: result.context,
          isPrivate: false,
        });
        dispatch({
          type: AccountActionName.GET_ACCOUNT_CONTEXT,
          result: { ...result, isPrivate: ctx?.isPrivate },
        });
      });
  };

export const switchAccount = (accountNumber: number) =>
  request(() => api.switchAccount(accountNumber).then((res) => res.data), {
    type: AccountActionName.SWITCH_ACCOUNT,
  });

/**
 * Switch account and get account and account context.
 *
 * @param accountNumber user account number or team account number
 * @param isTeamContext is redirected to a team context?
 * @param customRedirectUrl URL to redirect if the switch is successful
 */
export const switchAndRefreshAccount =
  (
    accountNumber: number | string,
    isTeamContext?: boolean,
    customRedirectUrl?: string
  ): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    const user = getState().user.profile;
    let redirectUrl = '/account/profile';
    if (isTeamContext) {
      try {
        const userTeam = user?.teams.find(
          (t) => t.accountNumber.toString() === accountNumber.toString()
        );
        const userRoles = userTeam?.roles || [];
        const team = await api.getTeamByAccount(accountNumber).then((res) => res.data);
        if (team.forceMfa && !userRoles.includes('teamOwner') && !user?.mfaEnabled) {
          dispatch(
            showNotification({
              status: 'failure',
              message: errorMessages.teamForceMfa,
              delay: 1000 * 10,
            })
          );
          history.replace('/account/advanced');
          return;
        }
        redirectUrl = `/u/${accountNumber}/settings/team/settings`;
      } catch (error) {
        dispatch(
          showNotification({ status: 'failure', message: errorMessages.switchAccountFailure })
        );
      }
    }
    try {
      const result = await api.switchAccount(accountNumber).then((res) => res.data);
      dispatch({ type: AccountActionName.SWITCH_ACCOUNT, result });
      const ctx = {
        accountNumber: result.accountNumber,
        context: result.context,
        isPrivate: false,
      };
      if (customRedirectUrl) {
        window.location.href = parseSwitchURL(ctx, customRedirectUrl);
        return;
      }
      await dispatch(getAccountContext(ctx));
      if (isTeamContext) {
        await dispatch(getTeamAccount(accountNumber));
      }
      // if on settings page, go to correct context
      const { pathname } = window.location;
      if (
        pathname.includes('/account') ||
        pathname.includes('/settings/team') ||
        pathname.includes('/teams')
      ) {
        history.replace(redirectUrl);
      }
    } catch (error: any) {
      dispatch(reduxErrorBanner(error, errorKeys, errorMessages.switchAccountFailure));
    }
  };
